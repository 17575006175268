import axios, {AxiosResponse} from "axios";
import {IUser} from "../../Entities/User";


export const GetAllUsers = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/users`, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        async (response: any) => {
            const usersPromises = response.data.map(async (user: any) => {
                return {
                    id: user["id"],
                    username: user["username"],
                    email: user["email"],
                    role: user["role"],
                    image: user["image"],
                    confirmedMail: user["confirmedMail"],
                };
            });

            const users = await Promise.all(usersPromises);
            return users;
        }
    );
}