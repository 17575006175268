// @ts-nocheck

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from "react-router-dom";
import LocalBarIcon from '@mui/icons-material/LocalBar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KitchenIcon from '@mui/icons-material/Kitchen';
import EuroIcon from '@mui/icons-material/Euro';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CelebrationIcon from '@mui/icons-material/Celebration';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';


import useMediaQuery from '@mui/material/useMediaQuery';


import {Box, CssBaseline, IconButton, Typography} from '@mui/material';
import {IUser} from "../Entities/User";


const drawerWidth: number = 240;
const adminPages: string[] = ['Getränke', 'Verkäufe', 'Kunden', 'Lager', 'Umsatz', 'Events', 'Öffnungszeiten'];
const staffPages: string[] = ['Getränke', 'Kunden'];


const TopBar: React.FC = (props: {
    user: IUser,
    setUser: (user: IUser) => void,
    selectedPage: string,
    setSelectedPage: (page: string) => void
}) => {

    const {user, setUser, selectedPage, setSelectedPage} = props;

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const isSmallScreen = useMediaQuery('(max-width:1200px)');

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, page: String) => {
        setSelectedPage(page);
        if (page === 'Home') {
            navigate('/');
        } else if (page === 'Getränke') {
            navigate('/drinks');
        } else if (page === 'Verkäufe') {
            navigate('/bills');
        } else if (page === 'Kunden') {
            navigate('/customers');
        } else if (page === 'Lager') {
            navigate('/storage');
        } else if (page === 'Umsatz') {
            navigate('/sales');
        } else if (page === 'Events') {
            navigate('/events');
        } else if (page === 'Öffnungszeiten') {
            navigate('/openingHours');
        }
    }

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            display: 'flex',
            flexDirection: 'row',
            border: 1,
            width: '100%',
            zIndex: 100,
            backgroundColor: localStorage.getItem('theme') === 'dark' ? 'rgb(48,48,48)' : 'rgb(255,255,255)',
        }}>
            <CssBaseline/>
            <div style={{width: '100%', zIndex: 100}}>
                {
                    localStorage.getItem('theme') === 'dark' ?
                        <IconButton onClick={() => {
                            localStorage.setItem('theme', 'light');
                            window.location.reload();
                        }}
                                    sx={{
                                        position: 'fixed',
                                        left: 0,
                                        zIndex: 100
                                    }}>
                            <DarkModeIcon/>
                        </IconButton>
                        :
                        <IconButton onClick={() => {
                            localStorage.setItem('theme', 'dark');
                            window.location.reload();
                        }}
                                    sx={{
                                        position: 'fixed',
                                        left: 0,
                                        zIndex: 100
                                    }}>
                            <LightModeIcon/>
                        </IconButton>
                }
                <Typography variant="h2" sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '10vh',
                    backgroundColor: 'rgb(192,173,109)',
                    color: 'white',
                    borderStyle: "solid",
                    borderColor: "black",
                    cursor: "pointer",
                    fontFamily: 'Helvetica Neue',
                }} onClick={() => {
                    setSelectedPage("Home");
                    navigate('/');
                }}>
                    Bittersüß
                </Typography>
                {
                    user !== null && user.role.includes("E") ?
                        <List hidden={!open} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                            {staffPages.map((text, index) => (
                                <ListItem key={text} sx={{
                                    backgroundColor: selectedPage === text ? 'rgba(236,210,139,0.48)' : 'rgba(255,255,255,0)',
                                }}>
                                    <ListItemButton onClick={(e) => handleListItemClick(e, text)}
                                                    sx={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                        <ListItemIcon sx={{minWidth: 'unset', marginRight: 0}}>
                                            {index === 0 ? <HomeIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 1 ? <LocalBarIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 2 ? <FaceRetouchingNaturalIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                        </ListItemIcon>
                                        {!isSmallScreen && <ListItemText primary={text}/>}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                        :
                        <List hidden={!open} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            {adminPages.map((text, index) => (
                                <ListItem key={text} sx={{
                                    backgroundColor: selectedPage === text ? 'rgba(236,210,139,0.48)' : (
                                        localStorage.getItem('theme') === 'light' ? 'rgb(255,255,255)' : 'rgba(0,0,0,0.1)'
                                    ),
                                }}>
                                    <ListItemButton onClick={(e) => handleListItemClick(e, text)}
                                                    sx={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                        <ListItemIcon sx={{minWidth: 'unset', marginRight: 0}}>
                                            {index === 0 ? <LocalBarIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 1 ? <ShoppingCartIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 2 ? <FaceRetouchingNaturalIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 3 ? <KitchenIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 4 ? <EuroIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 5 ? <CelebrationIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                            {index === 6 ? <QueryBuilderIcon style={{ color: localStorage.getItem('theme') === 'light' ? 'black' : 'white' }}/> : null}
                                        </ListItemIcon>
                                        {!isSmallScreen && <ListItemText primary={text}/>}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                }
            </div>
        </Box>
    );
}

export default TopBar;