// @ts-nocheck
import React from "react";
import {
    Autocomplete, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, IconButton, Slider,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {SaveDrinkStorage} from "../Services/DrinkStorageServices/SaveDrinkStorage";
import CloseIcon from "@mui/icons-material/Close";
import {IEvent} from "../Entities/Event";
import {AddEvent} from "../Services/EventServices/AddEvent";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";


const AddEventDialog = (props: {
    open: boolean,
    setOpen: (b: boolean) => void,
    onClose: () => void,
    events: IEvent[],
    setEvents: (events: IEvent[]) => void
}) => {
    const {open, setOpen, onClose, events, setEvents} = props;

    const [eventName, setEventName] = React.useState<string>('');
    const [eventDescription, setEventDescription] = React.useState<string>('');
    const [location, setLocation] = React.useState<string>('');
    const [startDate, setStartDate] = React.useState<Date>(dayjs(new Date()));
    const [endDate, setEndDate] = React.useState<Date>(dayjs(new Date().setDate(new Date().getDate() + 1)));

    React.useEffect(() => {
        console.log(startDate);
        console.log(endDate);
    }, [endDate])

    const handleAdd = () => {
        const event: IEvent = {
            name: eventName,
            description: eventDescription,
            location: location,
            startDateTime: startDate.toISOString(),
            endDateTime: endDate.toISOString()
        }
        console.log(event);
        AddEvent(event).then(() => {
            events.push(event);
            setEvents(events);
            setOpen(false);
            onClose();
        });
    };

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                Event hinzufügen
                <IconButton onClick={onClose} sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    marginBottom: 1,
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Name des Events"
                    value={eventName}
                    onChange={(event) => setEventName(event.target.value)}
                    fullWidth
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Beschreibung"
                    multiline
                    value={eventDescription}
                    onChange={(event) => setEventDescription(event.target.value)}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Location"
                    value={location}
                    onChange={(event) => setLocation(event.target.value)}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 1,
                    marginTop:3
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Startet am"
                            value={dayjs(startDate)}
                            onChange={(date) => setStartDate(date?.toDate() || new Date())}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Endet am"
                            value={dayjs(endDate)}
                            onChange={(date) => setEndDate(date?.toDate() || new Date())}
                        />
                    </LocalizationProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} fullWidth sx={{
                    backgroundColor: '#934343',
                    color: '#ffffff',
                }}>
                    Abbrechen
                </Button>
                <Button onClick={handleAdd} fullWidth sx={{
                    backgroundColor: '#1b861b',
                    color: '#ffffff',
                }}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddEventDialog;