// @ts-nocheck
import {IUser} from "../Entities/User";
import React from "react";
import {IOpeningHour} from "../Entities/OpeningHour";
import {ISpecialDay} from "../Entities/SpecialDay";
import {IEmployeeShift} from "../Entities/EmployeeShift";
import {
    Box,
    Switch,
    Typography,
    styled,
    Card,
    CardContent,
    Select,
    MenuItem,
    Checkbox,
    InputLabel, FormControl, Divider, IconButton
} from "@mui/material";
import {LocalizationProvider, StaticDatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/de';
import {PickersDay, PickersDayProps} from '@mui/x-date-pickers/PickersDay';
import {AddOpeningHour} from "../Services/BusinessHoursServices/AddOpeningHour";
import {GetAllUsers} from "../Services/UserServices/GetAllUsers";
import Avatar from "@mui/material/Avatar";
import {IEvent} from "../Entities/Event";
import ListItemText from "@mui/material/ListItemText";
import CelebrationIcon from '@mui/icons-material/Celebration';
import {useNavigate} from "react-router-dom";
import {AddExceptionDay} from "../Services/BusinessHoursServices/AddExceptionDay";
import {IExceptionDay} from "../Entities/ExceptionDay";
import {DeleteExceptionDay} from "../Services/BusinessHoursServices/DeleteExceptionDay";
import {UpdateEmployeeShift} from "../Services/EmployeeShiftsServices/UpdateEmployeeShift";
import {AddEmployeeShift} from "../Services/EmployeeShiftsServices/AddEmployeeShift";
import CloseIcon from "@mui/icons-material/Close";


const dayOfWeekMap = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY'
};

const dayOfWeekToEng = {
    'MONTAG': 'MONDAY',
    'DIENSTAG': 'TUESDAY',
    'MITTWOCH': 'WEDNESDAY',
    'DONNERSTAG': 'THURSDAY',
    'FREITAG': 'FRIDAY',
    'SAMSTAG': 'SATURDAY',
    'SONNTAG': 'SUNDAY'
}

const dayOfWeekToGer = {
    'MONDAY': 'MONTAG',
    'TUESDAY': 'DIENSTAG',
    'WEDNESDAY': 'MITTWOCH',
    'THURSDAY': 'DONNERSTAG',
    'FRIDAY': 'FREITAG',
    'SATURDAY': 'SAMSTAG',
    'SUNDAY': 'SONNTAG'
}

const HighlightedDay = styled(PickersDay)(({theme}) => ({
    "&.Mui-selected": {
        backgroundColor: '#aeaf8d',
        color: theme.palette.primary.contrastText,
    },
    "&.highlighted": {
        backgroundColor: '#76944c',
        color: theme.palette.info.contrastText,
    },
    "&.event": {
        backgroundColor: 'rgba(192,170,105,0.78)',
        color: theme.palette.success.contrastText,
    },
    "&.closed": {
        backgroundColor: '#602929',
        color: theme.palette.error.contrastText,
    },
}));

const WarningBox = styled(Box)(({theme}) => ({
    backgroundColor: 'rgba(255, 235, 59, 0.3)', // light yellow color
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
}));

const CustomDay = (props: {
    highlightedDays: string[],
    eventIntervals: { startDateTime: dayjs.Dayjs, endDateTime: dayjs.Dayjs }[],
    closedDays: string[],
    day: Dayjs,
    outsideCurrentMonth: boolean,
}) => {
    const {
        highlightedDays = [],
        eventIntervals = [],
        closedDays = [],
        day,
        outsideCurrentMonth,
        ...other
    } = props;

    const isHighlighted = highlightedDays.includes(dayOfWeekMap[day.day()]);
    const isEvent = eventIntervals.some(event => {
        const start = dayjs(event.startDateTime);
        const end = dayjs(event.endDateTime);
        return day.isBetween(start, end, 'day', '[]');
    });
    // All days that are closed which are not in the openingHours and not is event days
    const isClosed = closedDays.includes(dayOfWeekMap[day.day()]); // && !isHighlighted && !isEvent;

    return (
        <HighlightedDay
            {...other}
            day={day}
            outsideCurrentMonth={outsideCurrentMonth}
            className={isEvent ? "event" : isHighlighted ? "highlighted" : isClosed ? "closed" : ""}
        />
    );
};


const BusinessHours = (props: {
    user: IUser,
    openingHours: IOpeningHour[],
    setOpeningHours: (openingHours: IOpeningHour[]) => void
    employeeShifts: IEmployeeShift[],
    setEmployeeShifts: (employeeShifts: IEmployeeShift[]) => void,
    events: IEvent[],
    setSelectedPage: (page: string) => void,
    exceptionDays: IExceptionDay[],
    setExceptionDays: (exceptionDays: IExceptionDay[]) => void
}) => {

    const {
        user,
        openingHours,
        setOpeningHours,
        employeeShifts,
        setEmployeeShifts,
        events,
        setSelectedPage,
        exceptionDays,
        setExceptionDays
    } = props;

    const navigate = useNavigate();

    const [users, setUsers] = React.useState<IUser[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
    const [selectedDay, setSelectedDay] = React.useState<IOpeningHour | null>(null);
    const [shift1Start, setShift1Start] = React.useState<Date>('12:00');
    const [shift2Start, setShift2Start] = React.useState<Date>('18:00');
    const [employeesShift1, setEmployeesShift1] = React.useState<IEmployeeShift>([]);
    const [employeesShift2, setEmployeesShift2] = React.useState<IEmployeeShift>([]);
    const [selectedExceptionDay, setSelectedExceptionDay] = React.useState<IExceptionDay | null>(null);
    const [deleteExceptionDay, setDeleteExceptionDay] = React.useState<boolean>(false);
    const [addExceptionDay, setAddExceptionDay] = React.useState<boolean>(false);

    React.useEffect(() => {
        GetAllUsers().then((users) => {
            setUsers(users);
        });
    }, []);

    React.useEffect(() => {
        if (selectedDate && addExceptionDay && !deleteExceptionDay) {
            const exceptionDay = exceptionDays.find(exceptionDay => dayjs(exceptionDay.date).isSame(selectedDate, 'day'));
            setSelectedExceptionDay({
                date: selectedDate.toDate(),
                isClosed: exceptionDay?.isClosed,
                startTime: exceptionDay?.startTime,
                endTime: exceptionDay?.endTime
            });
            setExceptionDays([...exceptionDays, selectedExceptionDay]);
        }
    }, [addExceptionDay, deleteExceptionDay]);

    const getSplitEmployeeShifts = (dayOfWeek: string) => {
        if (employeeShifts.length === 0) {
            return [];
        }
        const shift1 = employeeShifts.find(shift => shift.dayOfWeek === dayOfWeek && shift.startTime === shift1Start);
        const shift2 = employeeShifts.find(shift => shift.dayOfWeek === dayOfWeek && shift.startTime === shift2Start);
        return [shift1, shift2];
    }

    const getNextSevenDays = () => {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const day = dayjs().add(i, 'day').locale('de');
            days.push({
                date: day.format('YYYY-MM-DD'),
                dayName: day.format('dddd'),
            });
        }
        return days;
    };

    const nextSevenDays = getNextSevenDays();

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date);

        const shifts: IEmployeeShift[] = getSplitEmployeeShifts(date.day());
        console.log(shifts);
        if (shifts.length > 0) {
            setEmployeesShift1(shifts[0]);
            setEmployeesShift2(shifts[1]);
        } else {
            setEmployeesShift1({
                startTime: shift1Start,
                endTime: shift2Start,
                dayOfWeek: dayOfWeekToEng[date.format('dddd').toUpperCase()],
                employee: users[1],
                workDate: new Date(date)
            });
            setEmployeesShift2({
                startTime: shift2Start,
                endTime: selectedDay?.endTime,
                dayOfWeek: dayOfWeekToEng[date.format('dddd').toUpperCase()],
                employee: users[1],
                workDate: new Date(date)
            });
        }

        if (date) {
            const openingHourFound = openingHours.find(openingHour =>
                dayOfWeekMap[date.day()] === openingHour.dayOfWeek
            );
            setSelectedDay(openingHourFound ? openingHourFound : {
                dayOfWeek: dayOfWeekToEng[date.format('dddd').toUpperCase()],
                startTime: '12:00',
                endTime: '23:00',
                isClosed: false
            });

            const exceptionDayFound = exceptionDays.find(exceptionDay => dayjs(exceptionDay.date).isSame(date, 'day'));
            setSelectedExceptionDay(exceptionDayFound ? exceptionDayFound : {
                date: date.toDate(),
                isClosed: selectedDay?.isClosed,
                startTime: selectedDay?.startTime,
                endTime: selectedDay?.endTime
            });
            setIsDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleSave = () => {
        if (!openingHours.some(openingHour => openingHour.dayOfWeek === selectedDay?.dayOfWeek)) {
            AddOpeningHour(selectedDay).then((response) => {
                setOpeningHours([...openingHours, response]);
            });
        } else {
            if (addExceptionDay) {
                AddExceptionDay({
                    date: selectedDate?.toDate(),
                    isClosed: selectedDay?.isClosed,
                    startTime: selectedDay?.startTime,
                    endTime: selectedDay?.endTime
                }).then((response) => {
                });
            } else if (deleteExceptionDay) {
                DeleteExceptionDay(selectedExceptionDay).then((response) => {
                });
            }

            // Check if the employeeShifts are already in the database
            const isShift1InDB = employeeShifts.find(shift => shift.dayOfWeek === selectedDay?.dayOfWeek && shift.startTime === shift1Start);
            const isShift2InDB = employeeShifts.find(shift => shift.dayOfWeek === selectedDay?.dayOfWeek && shift.startTime === shift2Start);

            if (isShift1InDB && isShift2InDB) {
                UpdateEmployeeShift(employeesShift1).then((response) => {
                    setEmployeeShifts([...employeeShifts, response]);
                    UpdateEmployeeShift(employeesShift2).then((response) => {
                        setEmployeeShifts([...employeeShifts, response]);
                    });
                });
            } else if (isShift1InDB && !isShift2InDB) {
                UpdateEmployeeShift(employeesShift1).then((response) => {
                    setEmployeeShifts([...employeeShifts, response]);
                    AddEmployeeShift(employeesShift2).then((response) => {
                        setEmployeeShifts([...employeeShifts, response]);
                    });
                });
            } else if (!isShift1InDB && isShift2InDB) {
                AddEmployeeShift(employeesShift1).then((response) => {
                    setEmployeeShifts([...employeeShifts, response]);
                    UpdateEmployeeShift(employeesShift2).then((response) => {
                        setEmployeeShifts([...employeeShifts, response]);
                    });
                });
            } else {
                AddEmployeeShift(employeesShift1).then((response) => {
                    setEmployeeShifts([...employeeShifts, response]);
                    AddEmployeeShift(employeesShift2).then((response) => {
                        setEmployeeShifts([...employeeShifts, response]);
                    });
                });
            }
        }

        setIsDialogOpen(false);
    };

    const getEventBetweenDates = (start: Dayjs, end: Dayjs) => {
        return events.filter(event => {
            const eventStart = dayjs(event.startDateTime);
            const eventEnd = dayjs(event.endDateTime);
            return eventStart.isBefore(end) && eventEnd.isAfter(start);
        });
    }

    const handleChangeEmployeeShift1 = (e: React.ChangeEvent<{ value: unknown }>) => {
        const selectedEmployeeUsername = e.target.value as string;
        const selectedEmployee = users.find((user) => user.username === selectedEmployeeUsername);
        if (selectedEmployee && selectedDay) {
            const updatedShift: IEmployeeShift = {
                startTime: shift1Start,
                endTime: shift2Start,
                dayOfWeek: selectedDay.dayOfWeek,
                employee: selectedEmployee,
                workDate: new Date(selectedDate),
            };
            setEmployeesShift1(updatedShift);
        }
    };

    const isRegularDayException = () => {
        return exceptionDaysWithOpeningHours.some(exceptionDay => selectedDate?.isSame(dayjs(exceptionDay.date), 'day'));
    }

    const handleChangeEmployeeShift2 = (e: React.ChangeEvent<{ value: unknown }>) => {
        const selectedEmployeeUsername = e.target.value as string;
        const selectedEmployee = users.find((user) => user.username === selectedEmployeeUsername);
        if (selectedEmployee && selectedDay) {
            const updatedShift: IEmployeeShift = {
                startTime: shift2Start,
                endTime: selectedDay.endTime,
                dayOfWeek: selectedDay.dayOfWeek,
                employee: selectedEmployee,
                workDate: new Date(selectedDate),
            };
            setEmployeesShift2(updatedShift);
        }
    };

    const openDays = openingHours.filter(openingHour => openingHour.dayOfWeek).map(openingHour => !openingHour.isClosed ? openingHour.dayOfWeek : '');
    // Connect ExeptionDays with openingHours
    const exceptionDaysWithOpeningHours = exceptionDays.map(exceptionDay => {
        const openingHour = openingHours.find(openingHour => openingHour.dayOfWeek === dayOfWeekToEng[dayjs(exceptionDay.date).format('dddd').toUpperCase()]);
        return {
            ...exceptionDay,
            startTime: openingHour?.startTime,
            endTime: openingHour?.endTime,
            isClosed: openingHour?.isClosed
        }
    });

    const eventIntervals = events.map(event => ({
        startDateTime: dayjs(event.startDateTime),
        endDateTime: dayjs(event.endDateTime)
    }));
    const closedDays = openingHours.filter(openingHour => openingHour.isClosed).map(openingHour => openingHour.dayOfWeek);

    return (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            boxSizing: 'border-box'
        }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '1000px',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        slots={{
                            day: CustomDay,
                        }}
                        slotProps={{
                            day: {
                                highlightedDays: openDays,
                                eventIntervals: eventIntervals,
                                closedDays: closedDays,
                            },
                        }}
                        sx={{
                            '.MuiPickersCalendar-root': {
                                height: '100%',
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            },
                            '.MuiPickersStaticWrapper-root': {
                                height: '100%',
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        marginTop: 20,
                        width: '60%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        padding: 2,
                        boxSizing: 'border-box',
                        maxHeight: '80vh',
                        overflowY: 'auto'
                    }}
                >
                    {nextSevenDays.map((day, index) => {
                        const openingHour = openingHours.find(openingHour => openingHour.dayOfWeek === String(dayOfWeekToEng[day.dayName.toUpperCase()]) && !openingHour.isClosed);
                        // eventsOnDay, check if events are between start and end of the day
                        const eventsOnDay = events.filter(event => {
                            const start = dayjs(event.startDateTime);
                            const end = dayjs(event.endDateTime);
                            return dayjs(day.date).isBetween(start, end, 'day', '[]');
                        });

                        return (
                            <Card key={index}
                                  sx={{
                                      minWidth: 230,
                                      minHeight: 150,
                                      backgroundColor: eventsOnDay.length > 0 ? 'rgba(192,170,105,0.78)' : openingHour ? '#76944c' : '#602929',
                                  }}
                            >
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        alignItems: 'center'
                                    }}>
                                        <Box>
                                            <Avatar
                                                sx={{backgroundColor: 'white', color: 'black'}}
                                                alt={user !== null ? user.username : day.dayName}
                                                src={user !== null && user.image !== undefined ? `data:image/jpeg;base64,${user?.image}` : day}
                                            />
                                            <Avatar
                                                sx={{backgroundColor: 'white', color: 'black'}}
                                                alt={user !== null ? user.username : day.dayName}
                                                src={user !== null && user.image !== undefined ? `data:image/jpeg;base64,${user?.image}` : day}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography variant="h8" component="div" sx={{color: 'white'}}>
                                                {day.dayName}
                                            </Typography>
                                            <Typography variant="h8" component="div" sx={{color: 'white'}}>
                                                {day.date}
                                            </Typography>
                                            <Divider/>
                                            {openingHour && eventsOnDay.length === 0 ? (
                                                <Typography variant="body2" sx={{color: 'white'}}>
                                                    Öffnungszeit:
                                                    <Typography>
                                                        {openingHour?.startTime}
                                                        - {openingHour?.endTime}
                                                    </Typography>
                                                </Typography>
                                            ) : (
                                                eventsOnDay.length === 0 ?
                                                    <Typography variant="body2" sx={{color: 'white'}}>
                                                        Geschlossen
                                                    </Typography>
                                                    :
                                                    null
                                            )}
                                            {eventsOnDay.length > 0 && (
                                                <Typography variant="body2" color="text.secondary">
                                                    <Typography sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: '1.5em',
                                                    }}>
                                                        Event
                                                    </Typography>
                                                    {eventsOnDay.map(event => (
                                                        <div key={event.id}>
                                                            <strong>{event.name}</strong>
                                                        </div>
                                                    ))}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                </Box>
                <Dialog open={isDialogOpen} onClose={handleDialogClose} fullScreen>
                    <DialogTitle>Informationen
                        für {selectedDay !== undefined && selectedDay?.dayOfWeek !== undefined ? (String(dayOfWeekToGer[selectedDay?.dayOfWeek])[0] + dayOfWeekToGer[selectedDay?.dayOfWeek].slice(1).toLowerCase()) : ''} [{selectedDate !== undefined ? selectedDate?.toDate().toISOString().split('T')[0] : ''}]
                        <IconButton onClick={handleDialogClose}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h4">
                            Allgemein
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography>
                                Geschlossen?
                            </Typography>
                            <Switch
                                checked={selectedDay?.isClosed}
                                onChange={(e) => {
                                    setSelectedDay({...selectedDay, isClosed: e.target.checked});
                                }}
                            />
                            <WarningBox>
                                <Typography variant="body1">
                                    Wenn an diesem Tag geschlossen ist,
                                    werden alle Öffnungszeiten und Schichten nicht bearbeitbar sein.
                                    Die Daten werden trotzdem in der Datenbank gespeichert!
                                </Typography>
                            </WarningBox>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            marginTop: 2
                        }}>
                            <TextField
                                label="Öffnungszeit"
                                type="time"
                                value={selectedDay?.startTime}
                                onChange={(e) => {
                                    setSelectedDay({...selectedDay, startTime: e.target.value});
                                }}
                                disabled={selectedDay?.isClosed}
                            />
                            <TextField
                                label="Schließzeit"
                                type="time"
                                value={selectedDay?.endTime}
                                onChange={(e) => {
                                    setSelectedDay({...selectedDay, endTime: e.target.value});
                                }}
                                disabled={selectedDay?.isClosed}
                            />
                            <Divider/>
                            <Typography variant="h4">
                                Schichtplanung
                            </Typography>
                            <Typography variant="h6">
                                Schicht 1
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                alignItems: 'center'
                            }}>
                                <TextField
                                    label="Start"
                                    type="time"
                                    fullWidth
                                    value={shift1Start}
                                    onChange={(e) => {
                                        setShift1Start(e.target.value);
                                    }}
                                    disabled={selectedDay?.isClosed}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="single-select-label">Mitarbeiter</InputLabel>
                                    <Select
                                        labelId="single-select-label"
                                        value={employeesShift1?.employee?.username}
                                        onChange={(e) => handleChangeEmployeeShift1(e)}
                                        disabled={selectedDay?.isClosed}
                                    >
                                        {users.map((user) => (
                                            <MenuItem key={user.id} value={user.username}>
                                                <Checkbox
                                                    checked={employeesShift1?.employee?.username === user.username}/>
                                                <ListItemText primary={user.username}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Divider/>
                            <Typography variant="h6">
                                Schicht 2
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                alignItems: 'center'
                            }}>
                                <TextField
                                    label="Start"
                                    type="time"
                                    fullWidth
                                    value={shift2Start}
                                    onChange={(e) => {
                                        setShift2Start(e.target.value);
                                    }}
                                    disabled={selectedDay?.isClosed}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="single-select-label">Mitarbeiter</InputLabel>
                                    <Select
                                        labelId="single-select-label"
                                        value={employeesShift2?.employee?.username}
                                        onChange={(e) => handleChangeEmployeeShift2(e)}
                                        disabled={selectedDay?.isClosed}
                                    >
                                        {users.map((user) => (
                                            <MenuItem key={user.id} value={user.username}>
                                                <Checkbox
                                                    checked={employeesShift2?.employee?.username === user.username}/>
                                                <ListItemText primary={user.username}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Divider/>
                        </Box>
                        <Typography variant="h4">
                            Event
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography>
                                Event?
                            </Typography>
                            <Switch
                                label="Event"
                                type="checkbox"
                                disabled
                                checked={getEventBetweenDates(dayjs(selectedDate).startOf('day'), dayjs(selectedDate).endOf('day')).length > 0}
                            />
                            <WarningBox>
                                <Typography variant="body1">
                                    Events können hier nicht bearbeitet werden. Bitte bearbeite Events im {
                                    <IconButton
                                        onClick={() => {
                                            handleDialogClose();
                                            setSelectedPage("Events");
                                            navigate('/events');
                                        }}
                                        sx={{
                                            backgroundColor: '#cc9b95'
                                        }}>
                                        <CelebrationIcon/>
                                    </IconButton>
                                }-Tab. Events werden hier nur angezeigt.
                                </Typography>
                            </WarningBox>
                        </Box>
                        <TextField
                            label="Eventbeschreibung"
                            multiline
                            fullWidth
                            disabled
                            value={getEventBetweenDates(dayjs(selectedDate).startOf('day'), dayjs(selectedDate).endOf('day')).map(event => event.description).join(', ') || ''}
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}
                        />
                        <TextField
                            label="Event"
                            select
                            fullWidth
                            disabled
                            sx={{marginBottom: 2}}
                            value={getEventBetweenDates(dayjs(selectedDate).startOf('day'), dayjs(selectedDate).endOf('day')).map(event => event.id).join(', ') || ''}
                        >
                            {events.map((event) => (
                                <MenuItem key={event.id} value={event.id}>
                                    {event.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Divider/>
                        <Typography variant="h4">
                            Ausnahmetag
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography>
                                Ausnahmetag?
                            </Typography>
                            <Switch
                                checked={isRegularDayException()}
                                onChange={(e) => {
                                    if (isRegularDayException() && !e.target.checked) {
                                        setDeleteExceptionDay(true);
                                        setExceptionDays(exceptionDays.filter(exceptionDay => dayjs(exceptionDay.date).isSame(selectedDate, 'day')));
                                    } else if (isRegularDayException() && e.target.checked) {
                                        setDeleteExceptionDay(false);
                                        setExceptionDays([...exceptionDays, selectedExceptionDay]);
                                    } else if (isRegularDayException() && !e.target.checked) {
                                        setAddExceptionDay(false);
                                        setExceptionDays(exceptionDays.filter(exceptionDay => dayjs(exceptionDay.date).isSame(selectedDate, 'day')));
                                    } else if (!isRegularDayException() && e.target.checked) {
                                        setAddExceptionDay(true);
                                        setExceptionDays([...exceptionDays, selectedExceptionDay]);
                                    }
                                }}
                            />
                            <WarningBox>
                                <Typography variant="body1">
                                    Wenn an diesem Tag ein Ausnahmetag ist, gelten die angegebenen Daten nur für diesen
                                    einen
                                    Tag <span
                                    style={{fontWeight: 'bold'}}>{selectedDate !== undefined ? selectedDate?.toDate().toISOString().split('T')[0] : ''}</span>.
                                </Typography>
                            </WarningBox>
                        </Box>
                    </DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 2,
                        padding: 2,
                        alignContent: 'center'
                    }}>
                        <Button onClick={handleDialogClose} fullWidth sx={{
                            backgroundColor: '#882424',
                            color: 'white'
                        }}>
                            Abbrechen
                        </Button>
                        <Button onClick={handleSave} fullWidth sx={{
                            backgroundColor: '#577a32',
                            color: 'white'
                        }}>
                            Speichern
                        </Button>
                    </Box>
                </Dialog>
            </LocalizationProvider>
        </Box>
    );


}


export default BusinessHours;