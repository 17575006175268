// @ts-nocheck
import {IDrinkStorage} from "../Entities/DrinkStorage";
import {
    Box,
    Button, IconButton, MenuItem, Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import React from "react";
import AddDrinkStorageDialog from "../Components/AddDrinkStorageDialog";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import LiquorIcon from '@mui/icons-material/Liquor';
import {IDrink} from "../Entities/Drink";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {DeleteDrinkStorage} from "../Services/DrinkStorageServices/DeleteDrinkStorage";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {IUser} from "../Entities/User";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from "@mui/icons-material/Delete";
import EditDrinkStorageDialog from "../Components/EditDrinkStorageDialog";


const DrinkStorage = (props: { user: IUser, storage: IDrinkStorage[], setStorage: (s: IDrinkStorage[]) => void, drinks: IDrink[] }) => {
    const {user, storage, setStorage, drinks } = props;

    const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
    const [isOpenEditDialog, setIsOpenEditDialog] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [selectedDrinkStorage, setSelectedDrinkStorage] = React.useState<IDrinkStorage | undefined>(undefined);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storage.length) : 0;

    React.useEffect(() => {
        if (selectedDrinkStorage) {
            setIsOpenEditDialog(true);
        }
    }, [selectedDrinkStorage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setIsOpenEditDialog(false);
        setSelectedDrinkStorage(null);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = (drinkStorage: IDrinkStorage) => {
        DeleteDrinkStorage(drinkStorage.id).then(() => {
            const index = storage.indexOf(drinkStorage);
            storage.splice(index, 1);
            setStorage([...storage]);
        });
    }

    return (
        <Box sx={{
            height: '80vh',
            maxHeight: '80vh',
            width: "100%",
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '25%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            { isOpenDialog && <AddDrinkStorageDialog open={isOpenDialog} setOpen={setIsOpenDialog} onClose={handleCloseDialog} drinks={drinks} /> }
            { isOpenEditDialog && <EditDrinkStorageDialog open={isOpenEditDialog} setOpen={setIsOpenEditDialog} onClose={handleCloseDialog} drinks={drinks} drinkStorage={selectedDrinkStorage}  setDrinkStorage={setSelectedDrinkStorage}/> }
            <TableContainer component={Paper} sx={{
                height: '75%',
                maxHeight: '75%',
            }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{
                            backgroundColor: 'rgba(192,173,109,0.35)'
                        }}>
                            <TableCell align="left" sx={{
                                fontWeight: 'bold',
                            }}>Kistenname</TableCell>
                            <TableCell align="left" sx={{
                                fontWeight: 'bold',
                            }}>Getränk</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Preis [€]</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Kisten im Lager</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Liter im Lager [L]</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? storage.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : storage
                        ).map((store: IDrinkStorage) => (
                            <TableRow key={store.id} sx={{
                                backgroundColor: store.currentBoxQuantity < store.warningQuantity ? 'rgba(255,217,92,0.34)' : (
                                    localStorage.getItem('theme') === 'dark' ? '#494949' : 'white'
                                )
                            }}>
                                <TableCell align="left">
                                    {
                                        store.currentBoxQuantity < store.warningQuantity &&
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#ffcc59'
                                        }}>
                                            <WarningAmberIcon sx={{
                                                color: 'black',
                                            }}/>
                                        </Button>
                                    }
                                    {store.name}
                                </TableCell>
                                <TableCell align="left">
                                    <Select
                                        value={store.drink.name}
                                        disabled={true}
                                    >
                                        {drinks.map((drink) => (
                                            <MenuItem key={drink.id} value={drink.name} onClick={() => {
                                                store.drink = drink;
                                                setStorage([...storage]);
                                            }}>
                                                {drink.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell align="center">{store.price}</TableCell>
                                <TableCell align="center">{parseInt(store.currentBoxQuantity)}</TableCell>
                                <TableCell align="center">{parseFloat(store.currentLiterQuantity).toFixed(2)}</TableCell>
                                <TableCell align="center">
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#1b861b'
                                        }}>
                                            <EditNoteIcon onClick={() => setSelectedDrinkStorage(store)} sx={{
                                                color: 'white',
                                            }}/>
                                        </Button>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#861b1b'
                                        }}>
                                            <DeleteIcon onClick={() => handleDelete(store)} sx={{
                                                color: '#ffffff',
                                            }}/>
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={storage.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}
            />
            { user.role.includes('A') &&
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 50,
                    right: 50,
                    zIndex: 99
                }}
            >
                <Fab aria-label="Neues Getränk hinzufügen" onClick={() => setIsOpenDialog(true)}
                     sx={{
                         backgroundColor: 'rgb(192,173,109)',
                         boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                         width: '100px',
                         height: '100px',
                     }}>
                    <AddIcon/>
                    <LiquorIcon/>
                </Fab>
            </Box>
            }
        </Box>
    );
}

export default DrinkStorage;