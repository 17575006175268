import {IDrinkLiterPerOrders} from "../../Entities/DrinkLiterPerOrders";
import axios from "axios";


export const UpdateDrinkLiterPerOrder = async (drinkLiterPerOrder: IDrinkLiterPerOrders) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.put(`${backendUrl}/drinkLiterPerOrders/${drinkLiterPerOrder.id}`, drinkLiterPerOrder, {
        headers: {
            'authorization': `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response;
        }
    );
}