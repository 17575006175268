import axios from "axios";


export const DeleteDrinkLiterPerOrder = async (id: number) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.delete(`${backendUrl}/drinkLiterPerOrders/${id}`, {
        headers: {
            'authorization': `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response;
        }
    );
}