import axios, {AxiosResponse} from "axios";
import {IUser} from "../../Entities/User";

export const CheckMailAvailability = async (user: IUser): Promise<AxiosResponse<boolean>> => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/users/mails/available`, user, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response) => {
            return response.data;
        }
    );
}