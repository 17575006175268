// @ts-nocheck
import Fuse from 'fuse.js';
import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box, Button,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle, Divider,
    Grid, IconButton, InputAdornment, MenuItem, Select, Switch,
    TextField,
    Typography
} from '@mui/material';
import {IDrink} from "../Entities/Drink";
import placeholderImage from '../images/placeholder.png';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {UploadDrinkImage} from "../Services/DrinksServices/UploadDrinkImage";
import {UpdateDrink} from "../Services/DrinksServices/UpdateDrink";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {DeleteDrink} from "../Services/DrinksServices/DeleteDrink";
import RemoveIcon from '@mui/icons-material/Remove';
import {SaveBills} from "../Services/BillsServices/SaveBill";
import {IBill} from "../Entities/Bill";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddDrinkButton from "../Components/AddDrinkButton";
import {IUser} from "../Entities/User";
import {SaveDrinkLiterPerOrder} from "../Services/DrinkLiterPerOrderServices/SaveDrinkLiterPerOrder";
import {GetAllDrinks} from "../Services/DrinksServices/GetAllDrinks";
import {DeleteDrinkLiterPerOrder} from "../Services/DrinkLiterPerOrderServices/DeleteDrinkLiterPerOrder";
import {UpdateDrinkLiterPerOrder} from "../Services/DrinkLiterPerOrderServices/UpdateDrinkLiterPerOrder";


const Drinks = (props: {
    drinks: IDrink[],
    setDrinks: (drink: IDrink) => void,
    user: IUser,
    setBillings: (bills: IBill[]) => void
}) => {
    const {drinks, setDrinks, user, setBillings} = props;

    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedDrink, setSelectedDrink] = useState<IDrink>({
        id: 0,
        name: "",
        description: "",
        price: 0.0,
        refPrice: 0.0,
        category: "",
        image: null,
        drinkExtraOptions: [],
    });
    const [overDelete, setOverDelete] = React.useState(false);
    const [clickedDelete, setClickedDelete] = React.useState(false);
    const [currentOrder, setCurrentOrder] = React.useState<IDrink[]>([]);
    const [hover, setHover] = React.useState(false);
    const [drinkCount, setDrinkCount] = React.useState(0);
    const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
    const [optionCount, setOptionCount] = React.useState(0);
    const [originalDrinks, setOriginalDrinks] = React.useState<IDrink[]>([]);
    const [availableCategories, setAvailableCategories] = React.useState<String[]>([]);
    const [drinkResetted, setDrinkResetted] = React.useState<boolean>(false);
    const [selectedExtraOptionPrice, setSelectedExtraOptionPrice] = React.useState<number>(0);
    const [isDrinkOrderAccordionOpen, setIsDrinkOrderAccordionOpen] = React.useState(false);
    const [selectedOrderDrinks, setSelectedOrderDrinks] = React.useState<IDrink[]>([]);

    const [categoryFilter, setCategoryFilter] = React.useState<string | null>(null);

    const categories = [...new Set(drinks.map((drink) => drink.category))];

    const options = {
        includeScore: true,
        keys: ['name']
    };

    let result;
    const fuse = new Fuse(drinks, options);
    if (search.trim() === '') {
        result = drinks;
    } else {
        result = fuse.search(search).map(({item}) => item);
    }

    React.useEffect(() => {
        GetAllDrinks(true).then((drinks) => {
            setOriginalDrinks(drinks);
            setDrinks(drinks);
        });
    }, [])

    React.useEffect(() => {
        if (drinkResetted) {
            changePriceWithSelectedExtraOption(selectedExtraOptionPrice);
        } else {
            resetSelectedDrinkAndDrinks();
        }
    }, [drinkResetted])

    React.useEffect(() => {
    }, [selectedDrink])

    React.useEffect(() => {
        // Get all available categories
        if (drinks.length === 0) {
            return;
        }
        const categories = drinks.map((d) => d.category);
        const uniqueCategories = [...new Set(categories)];
        setAvailableCategories(uniqueCategories);
    }, [drinks]);

    const handleOpen = () => {
        if (!overDelete && !hover) {
            resetSelectedDrinkAndDrinks();
            setOpen(true);
        }
    };

    const resetSelectedDrinkAndDrinks = () => {
        // Reset the price to the original price, original prices are in originalDrinks
        if (selectedDrink === null || selectedDrink === undefined) {
            return;
        }
        setDrinks([
            ...drinks.map(drink => {
                if (drink.id === selectedDrink.id) {
                    return {
                        ...drink,
                        price: originalDrinks.find(drink => drink.id === selectedDrink.id).price
                    }
                }
                return drink;
            })

        ]);
        const originalDrink = originalDrinks.find(drink => drink.id === selectedDrink.id);
        setSelectedDrink(originalDrink);
        setDrinkResetted(true);
    }

    const changePriceWithSelectedExtraOption = (extraPrice: number) => {
        if (extraPrice > 0) {
            setSelectedDrink({
                ...selectedDrink,
                price: selectedDrink?.price + extraPrice
            });
            // Update drinks array with the new price
            setDrinks(drinks.map(drink => {
                if (drink.id === selectedDrink.id) {
                    return {
                        ...drink,
                        price: drink.price + extraPrice
                    }
                }
                return drink;
            }));
        }
    }

    const handleClose = () => {
        setOverDelete(false);
        setClickedDelete(false);
        setOpen(false);
    };

    const saveChanges = () => {
        UpdateDrink(selectedDrink).then((updatedDrink) => {
            setSelectedDrink(updatedDrink);
        }).finally(() => {
            SaveDrinkLiterPerOrder(selectedDrink.drinkLiterPerOrders).then(() => {
                GetAllDrinks(false).then((drinks) => {
                    setDrinks(drinks);
                    setOriginalDrinks(drinks);
                }).finally(() => {
                    for (let i = 0; i < selectedDrink.drinkLiterPerOrders.length; i++) {
                        UpdateDrinkLiterPerOrder(selectedDrink.drinkLiterPerOrders).then(() => {
                            setOpen(false);
                            setIsDrinkOrderAccordionOpen(false);
                        });
                    }
                });
            })
        });
    }

    const addDrinkToOrder = (drink: IDrink) => {
        setCurrentOrder([...currentOrder, drink]);
    }

    const removeDrinkFromOrder = (drink: IDrink) => {
        // Remove the first occurrence of the drink from the order
        const index = currentOrder.findIndex(order => order.id === drink.id);
        if (index > -1) {
            currentOrder.splice(index, 1);
            setCurrentOrder([...currentOrder]);
        }
    }

    const openImageSelector = (id: number) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.jpg,.jpeg,.png';
        input.onchange = (event) => {
            const file = (event.target as HTMLInputElement).files[0];
            let result;
            if (file) {
                UploadDrinkImage(id, file).then((res) => {
                    result = res;
                }).catch(() => {
                    console.error('Image upload failed');
                }).finally(() => {
                    setSelectedDrink({
                        ...selectedDrink,
                        image: result
                    });
                    setDrinks(
                        drinks.map(drink => {
                            if (drink.id === id) {
                                return {
                                    ...drink,
                                    image: result
                                }
                            }
                            return drink;
                        }));
                });
            }
        };
        input.click();
    }

    const deleteDrink = () => {
        setClickedDelete(false);
        DeleteDrink(selectedDrink.id).then(() => {
            setDrinks(drinks.filter(drink => drink.id !== selectedDrink.id));
        });
    }

    const enableDeleteDrink = (drink: IDrink) => {
        setClickedDelete(true);
        setSelectedDrink(drink);
    }

    const changeDrinkName = (name: string) => {
        setSelectedDrink({...selectedDrink, name: name});
    }

    const changeDrinkDescription = (description: string) => {
        setSelectedDrink({...selectedDrink, description: description});
    }

    const changeDrinkCategory = (event: any, value: string) => {
        setSelectedDrink({...selectedDrink, category: value});
    }

    const changeDrinkPrice = (price: string) => {
        if (price === '' || price === null) {
            price = 0.0;
        } else {
            price = Number(price.replace(",", "."));
        }
        setSelectedDrink({...selectedDrink, price: price});
    }

    const changeDrinkRefPrice = (price: string) => {
        if (price === '' || price === null) {
            price = 0.0;
        } else {
            price = Number(price.replace(",", "."));
        }
        setSelectedDrink({...selectedDrink, refPrice: price});
    }

    const saveOrders = () => {
        const bills: IBill[] = [];
        // Create a bill for each drink in the order (Add the quantity of the drink and the price)
        currentOrder.forEach(drink => {
            // if drink.id not in bills
            if (bills.filter(bill => bill.drinkId === drink.id).length === 0) {
                const bill: IBill = {
                    date: new Date(),
                    drinkId: drink.id,
                    price: currentOrder.filter(order => order.id === drink.id).length * drink.price,
                    quantity: currentOrder.filter(order => order.id === drink.id).length,
                    userId: 1
                };
                bills.push(bill);
            }
        });

        SaveBills(bills).then(() => {
            setCurrentOrder([]);
            // setBillings(bills);
        });
    }

    const addOption = () => {
        setSelectedDrink({
            ...selectedDrink,
            drinkExtraOptions: [...selectedDrink.drinkExtraOptions, {
                "name": "",
                "price": 0,
                "defaultOption": false
            }],
        });
        setOptionCount(optionCount + 1);
    };

    const getPriceByDefaultOption = (drink: IDrink) => {
        if (drink === undefined) {
            return 0;
        }
        const defaultOption = drink.drinkExtraOptions.find(option => option.defaultOption);
        return defaultOption !== undefined ? (drink.price + defaultOption.price).toFixed(2).replace(".", ",") : drink.price.toFixed(2).replace(".", ",");
    }

    const addDrinkPerLiterOrder = () => {
        if (selectedDrink.drinkLiterPerOrders === null || selectedDrink.drinkLiterPerOrders === undefined) {
            setSelectedDrink({
                ...selectedDrink,
                drinkLiterPerOrders: [],
            });
        } else {
            setSelectedDrink({
                ...selectedDrink,
                drinkLiterPerOrders: [...selectedDrink.drinkLiterPerOrders, {
                    drink: selectedDrink,
                    additionalDrink: {
                        id: 0,
                        name: "",
                        description: "",
                        price: 0.0,
                        refPrice: 0.0,
                        category: "",
                        image: null,
                        drinkExtraOptions: [],
                        drinkLiterPerOrders: []
                    },
                    literPerOrder: 0
                }],
            });
        }
    }

    return (
        <Box sx={{
            maxWidth: '100vw',
            height: '92%',
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '25%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: 1,
                marginBottom: 1,
                overflowY: 'auto',
            }}>
                <Typography variant="h6">
                    Nach Getränken suchen
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Suche Getränk..."
                    value={search}
                    onChange={e => {
                        if (e.target.value) {
                            setSearch(e.target.value);
                        } else {
                            setSearch('');
                        }
                    }}
                    sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        border: 1
                    }}
                />
                <Typography variant="h6">
                    Nach Kategorie filtern
                </Typography>
                <Autocomplete
                    sx={{
                        width: "100%",
                        marginBottom: 2
                    }}
                    options={categories}
                    value={categoryFilter}
                    onChange={(event, newValue) => {
                        setCategoryFilter(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Filter nach Kategorie"/>}
                />
            </Box>
            <Box sx={{flexGrow: 1, m: 1, overflow: 'auto', height: 'calc(90vh - 350px)', width: '100%'}}>
                <Grid container spacing={1} padding={2}>
                    {result.filter(drink => drink.name && (search ? drink.name.toLowerCase().includes(search.toLowerCase()) : true) && (categoryFilter ? drink.category === categoryFilter : true)).map(drink => (
                        <Grid item xs={12} sm={4} md={4} lg={3} xl={2} key={drink.id}>
                            <Card
                                sx={{
                                    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)',
                                    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'rgba(206,168,25,0.66)' : 'rgba(192,173,109,0.48)',
                                    width: "100%",
                                    "&:hover": {
                                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,1.0)',
                                        cursor: "pointer",
                                        backgroundColor: localStorage.getItem('theme') === 'dark' ? 'rgba(192,173,109,0.74)' : 'rgba(192,173,109,0.74)',
                                        transform: 'scale(1.03)',
                                        transition: 'transform 0.3s ease-in-out',
                                    },
                                }}
                                onClick={() => {
                                    setSelectedDrink(drink);
                                    handleOpen();
                                }}
                                onPointerEnter={() => {
                                    setSelectedDrink(drink);
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {drink.name}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignContent: "center"
                                    }}>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignContent: "center"
                                        }}>
                                            <Typography variant="h6">
                                                {getPriceByDefaultOption(drink)}€
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignContent: "center"
                                        }}>
                                            <Select
                                                defaultValue={
                                                    drinkResetted ? 0 : drink.drinkExtraOptions.find(option => option.defaultOption)?.id || 0
                                                }
                                                disabled={drink.drinkExtraOptions.length === 0}
                                                onPointerEnter={() => {
                                                    setHover(true);
                                                    setSelectedDrink(drink);
                                                }}
                                                onPointerLeave={() => setHover(false)}
                                                fullWidth
                                            >
                                                <MenuItem value={0}
                                                          onClick={(event) => {
                                                              event.stopPropagation();
                                                              setDrinkResetted(false);
                                                              setSelectedExtraOptionPrice(0);
                                                              setHover(false);
                                                          }}>
                                                    Keine Option
                                                </MenuItem>
                                                {drink.drinkExtraOptions.map((option, index) => (
                                                    <MenuItem key={option.id} value={option.id}
                                                              onClick={(event) => {
                                                                  event.stopPropagation();
                                                                  setDrinkResetted(false);
                                                                  setSelectedExtraOptionPrice(option.price);
                                                                  setHover(false);
                                                              }}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        marginTop: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        alignContent: "center"
                                    }}>
                                        <IconButton sx={{
                                            color: "white",
                                            backgroundColor: "#8d3131",
                                            height: 55,
                                            width: "35%",
                                            "&:hover": {
                                                backgroundColor: "#771616",
                                                color: "white",
                                                boxShadow: 1,
                                                transform: 'scale(1.03)',
                                                transition: 'transform 0.3s ease-in-out',
                                            },
                                            borderRadius: 1,
                                            zIndex: 99
                                        }} onClick={(event) => {
                                            event.stopPropagation();
                                            removeDrinkFromOrder(drink);
                                        }}
                                                    onPointerEnter={() => setHover(true)}
                                                    onPointerLeave={() => setHover(false)}>
                                            <RemoveIcon/>
                                        </IconButton>
                                        <TextField sx={{
                                            textAlign: "center",
                                            borderRadius: 1,
                                            width: 50,
                                            height: 40,
                                            fontSize: 8,
                                            zIndex: 99
                                        }} onPointerEnter={() => setHover(true)}
                                                   onPointerLeave={() => setHover(false)}
                                                   value={currentOrder.filter(order => order.id === drink.id).length}
                                                   disabled={false}
                                                   onClick={(event) => event.stopPropagation()}
                                                   onChange={(e) => {
                                                       setDrinkCount(parseInt(e.target.value));
                                                       // Update the current order with the new quantity of the drink
                                                       setCurrentOrder([...currentOrder.filter(order => order.id !== drink.id), ...Array.from({length: parseInt(e.target.value)}, () => drink)]);
                                                   }}/>
                                        <IconButton sx={{
                                            color: "white",
                                            backgroundColor: "#599349",
                                            height: 55,
                                            width: "35%",
                                            "&:hover": {
                                                backgroundColor: "#599349",
                                                color: "white",
                                                boxShadow: 1,
                                                transform: 'scale(1.03)',
                                                transition: 'transform 0.3s ease-in-out',
                                            },
                                            borderRadius: 1,
                                            zIndex: 99
                                        }} onClick={(event) => {
                                            event.stopPropagation();
                                            setHover(true);
                                            addDrinkToOrder(drink)
                                        }}
                                                    onPointerEnter={() => setHover(true)}
                                                    onPointerLeave={() => setHover(false)}>
                                            <AddIcon/>
                                        </IconButton>
                                    </Box>
                                    <Button
                                        sx={{
                                            backgroundColor: "#599349",
                                            color: "white",
                                            marginTop: 2,
                                            "&:hover": {
                                                backgroundColor: "#2f7905",
                                                boxShadow: 1
                                            },
                                            "&:disabled": {
                                                backgroundColor: "#646464"
                                            },
                                            width: "100%"
                                        }}
                                        disabled={currentOrder.filter(order => order.id === drink.id).length == 0}
                                        onPointerEnter={() => setHover(true)}
                                        onPointerLeave={() => setHover(false)}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            saveOrders();
                                        }}
                                    >
                                        Bestellung hinzufügen
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Dialog open={open} onClose={handleClose} sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }} fullScreen>
                    <DialogTitle>
                        {selectedDrink?.name}
                        <IconButton style={{position: 'absolute', right: '8px', top: '8px'}}
                                    onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{
                        justifyContent: "space-around"
                    }}>
                        <Box position="relative">
                            {user.role.includes("A") || user.role.includes("T") ?
                                <Button onClick={() => enableDeleteDrink(selectedDrink)}
                                        fullWidth
                                        sx={{
                                            backgroundColor: "#8d3131",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#771616",
                                                boxShadow: 1
                                            },
                                            position: 'absolute',
                                            top: 0,
                                            right: 0
                                        }}
                                >
                                    Getränk löschen
                                    <DeleteForeverIcon/>
                                </Button> : null}
                            <CardMedia
                                component="img"
                                height="240"
                                image={(selectedDrink?.image !== undefined && selectedDrink?.image !== null) ? selectedDrink?.image : placeholderImage}
                                alt={selectedDrink?.name}
                                sx={{
                                    objectFit: "contain",
                                    backgroundColor: "#cbcbcb"
                                }}
                                onClick={(e) => {
                                    if (user.role.includes("A") || user.role.includes("T")) {
                                        openImageSelector(selectedDrink?.id);
                                    }
                                }}
                            />
                        </Box>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Name"
                            defaultValue={selectedDrink?.name}
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}
                            onChange={(e) => changeDrinkName(e.target.value)}
                        />
                        <Autocomplete
                            freeSolo
                            value={selectedDrink?.category}
                            options={availableCategories}
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Kategorie des Getränks"/>
                            )}
                            onChange={(event, value) => changeDrinkCategory(event, value)}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Beschreibung"
                            defaultValue={selectedDrink?.description}
                            multiline
                            rows={8}
                            onChange={(e) => changeDrinkDescription(e.target.value)}
                        />
                        <Accordion expanded={isDrinkOrderAccordionOpen}
                                   onChange={() => setIsDrinkOrderAccordionOpen(!isDrinkOrderAccordionOpen)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography>Bestellungsdetails</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    margin="dense"
                                    id="litersPerOrder"
                                    label="Liter pro Bestellung"
                                    type="number"
                                    value={selectedDrink?.literPerOrder}
                                    fullWidth
                                    onChange={(e) => {
                                        setSelectedDrink({
                                            ...selectedDrink,
                                            literPerOrder: Number(e.target.value),
                                        });
                                    }}
                                />
                                {selectedDrink?.drinkLiterPerOrders !== null && selectedDrink?.drinkLiterPerOrders !== undefined && Array.from({length: selectedDrink.drinkLiterPerOrders.length}, (_, index) => (
                                    <Box key={index} sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        flexDirection: 'row',
                                    }}>
                                        <Autocomplete
                                            id="drink-name-autocomplete"
                                            options={drinks}
                                            getOptionLabel={(option) => option.name}
                                            value={selectedDrink.drinkLiterPerOrders && selectedDrink.drinkLiterPerOrders[index].additionalDrink ?
                                                selectedDrink.drinkLiterPerOrders[index].additionalDrink : null}
                                            onChange={(e, value) => {
                                                setSelectedDrink({
                                                    ...selectedDrink,
                                                    drinkLiterPerOrders: selectedDrink.drinkLiterPerOrders.map((order, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...order,
                                                                additionalDrink: value
                                                            }
                                                        }
                                                        return order;
                                                    })
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    key={index}
                                                    type="text"
                                                    label={`Getränk ${index + 1}`}
                                                    fullWidth
                                                />
                                            )}
                                            fullWidth
                                        />
                                        <TextField
                                            key={index}
                                            type="number"
                                            label={`Liter pro Bestellung ${index + 1}`}
                                            value={selectedDrink.drinkLiterPerOrders !== undefined && selectedDrink.drinkLiterPerOrders[index] ? selectedDrink.drinkLiterPerOrders[index].literPerOrder : 0}
                                            onChange={(e) => {
                                                const literPerOrder = Number(e.target.value);
                                                setSelectedDrink({
                                                    ...selectedDrink,
                                                    drinkLiterPerOrders: selectedDrink.drinkLiterPerOrders.map((order, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...order,
                                                                literPerOrder: literPerOrder
                                                            }
                                                        }
                                                        return order;
                                                    })
                                                });
                                            }}
                                            fullWidth
                                        />
                                        <IconButton onClick={() => {
                                            const newDrinkOrders = selectedDrink.drinkLiterPerOrders;
                                            DeleteDrinkLiterPerOrder(selectedDrink.drinkLiterPerOrders[index].id).then(() => {
                                                newDrinkOrders.splice(index, 1);
                                                setSelectedDrink({
                                                    ...selectedDrink,
                                                    drinkLiterPerOrders: newDrinkOrders,
                                                });
                                            })
                                        }}>
                                            <CloseIcon sx={{color: 'red'}}/>
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button onClick={() => addDrinkPerLiterOrder()} fullWidth sx={{
                                    marginTop: 2,
                                    backgroundColor: '#4d8196',
                                    color: 'white',
                                }}>+</Button>
                            </AccordionDetails>
                        </Accordion>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Einkaufspreis"
                            type="number"
                            value={selectedDrink?.refPrice !== undefined ? selectedDrink?.refPrice.toFixed(2) : 0}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}
                            onChange={(e) => changeDrinkRefPrice(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Verkaufspreis"
                            type="number"
                            value={selectedDrink?.price !== undefined ? selectedDrink?.price.toFixed(2) : 0}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                            sx={{
                                marginTop: 2,
                                marginBottom: 2
                            }}
                            onChange={(e) => changeDrinkPrice(e.target.value)}
                        />
                        <Accordion expanded={isAccordionOpen} onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                onClick={() => {
                                }}
                                sx={{
                                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.35)',
                                }}
                            >
                                <Typography>Weitere Preisoptionen</Typography>
                            </AccordionSummary>
                            <AccordionDetails onClick={() => {
                            }} sx={{
                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                            }}>
                                {selectedDrink && selectedDrink?.drinkExtraOptions !== null && Array.from({length: selectedDrink.drinkExtraOptions.length}, (_, index) => (
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        flexDirection: 'row',
                                    }}>
                                        <TextField
                                            key={index + selectedDrink.id}
                                            type="text"
                                            label={`Optionname ${index + 1}`}
                                            value={selectedDrink.drinkExtraOptions[index].name}
                                            onChange={(e) => {
                                                const optionName = e.target.value;
                                                setSelectedDrink({
                                                    ...selectedDrink,
                                                    drinkExtraOptions: selectedDrink.drinkExtraOptions.map((option, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...option,
                                                                name: optionName
                                                            }
                                                        }
                                                        return option;
                                                    })
                                                });
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            key={index + selectedDrink.id + 1}
                                            type="number"
                                            label={`Optionprice ${index + 1}`}
                                            value={selectedDrink.drinkExtraOptions[index].price}
                                            onChange={(e) => {
                                                const optionPrice = Number(e.target.value);
                                                setSelectedDrink({
                                                    ...selectedDrink,
                                                    drinkExtraOptions: selectedDrink.drinkExtraOptions.map((option, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...option,
                                                                price: optionPrice
                                                            }
                                                        }
                                                        return option;
                                                    })
                                                });
                                            }}
                                            fullWidth
                                        />
                                        <Switch aria-label="Standard?"
                                                checked={selectedDrink.drinkExtraOptions[index].defaultOption}
                                                onChange={(e) => {
                                                    const optionDefault = e.target.checked;
                                                    setSelectedDrink({
                                                        ...selectedDrink,
                                                        drinkExtraOptions: selectedDrink.drinkExtraOptions.map((option, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...option,
                                                                    defaultOption: optionDefault
                                                                }
                                                            }
                                                            return option;
                                                        })
                                                    });
                                                }}>
                                        </Switch>
                                        <IconButton onClick={() => {
                                            const newOptionPrices = selectedDrink.drinkExtraOptions;
                                            newOptionPrices.splice(index, 1);
                                            setSelectedDrink({
                                                ...selectedDrink,
                                                optionPrices: newOptionPrices,
                                            });
                                            setOptionCount(optionCount - 1);
                                        }}>
                                            <CloseIcon sx={{color: 'red'}}/>
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button onClick={() => addOption()} fullWidth sx={{
                                    marginTop: 2,
                                    backgroundColor: '#4d8196',
                                    color: 'white',
                                }}>+</Button>
                            </AccordionDetails>
                        </Accordion>
                        {user.role.includes("A") || user.role.includes("T") ?
                            <Button sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                backgroundColor: "#599349",
                                color: "white",
                                width: "100%",
                                marginTop: 2,
                                "&:disabled": {
                                    backgroundColor: "#888888"
                                },
                                "&:hover": {
                                    backgroundColor: "#2f7905",
                                    boxShadow: 1
                                }
                            }}
                                    onClick={() => saveChanges()}
                                    disabled={
                                        selectedDrink?.name === ""
                                    }>
                                Änderungen speichern
                            </Button> : null}
                    </DialogContent>
                </Dialog>
                {clickedDelete && <Dialog open={clickedDelete} onClose={() => setClickedDelete(false)}>
                    <DialogTitle sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        Löschen
                        <IconButton onClick={() => setClickedDelete(false)}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Möchtest Du das Getränk [{selectedDrink?.name}] wirklich löschen?
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around"

                        }}>
                            <Button onClick={() => deleteDrink()}
                                    sx={{
                                        backgroundColor: "#6e2f2f",
                                        color: "white",
                                        marginTop: 2,
                                        "&:hover": {
                                            backgroundColor: "#771616",
                                            boxShadow: 1
                                        }
                                    }}
                                    fullWidth
                            >
                                Löschen
                            </Button>
                            <Button onClick={() => setClickedDelete(false)}
                                    sx={{
                                        backgroundColor: "#3b6e2f",
                                        color: "white",
                                        marginTop: 2,
                                        "&:hover": {
                                            backgroundColor: "#287716",
                                            boxShadow: 1
                                        }
                                    }}
                                    fullWidth
                            >
                                Abbrechen
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>}
            </Box>
            {
                user !== null && user.role.includes('A') ?
                    <AddDrinkButton drinks={drinks} setDrinks={setDrinks}/>
                    : null
            }
        </Box>
    );
}

export default Drinks;