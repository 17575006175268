// @ts-nocheck
import {createTheme} from '@mui/material/styles';

const darkTheme = createTheme({
    overrides: {
        MuiSwitch: {
            switchBase: {
                color: '#339379',
                '&$checked': {
                    color: '#33938b',
                },
                '&$checked + $track': {
                    backgroundColor: '#86c960',
                },
            },
            checked: {},
            track: {},
        },
        MuiTableRow: {
            root: {
                backgroundColor: '#707070',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid #707070',
            },
        },
        MuiTextField: {
            input: {
                "& .Mui-disabled": {
                    color: "rgba(218,218,218,0.6)" // (default alpha is 0.38)
                },
            },
        },
        MuiInput: {
            input: {
                "& .Mui-disabled": {
                    color: "rgba(218,218,218,0.6)" // (default alpha is 0.38)
                },
            },
        },
        MuiIconButton: {
            root: {
                color: '#ffffff',
            },
        }
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#7d9f51',
        },
        secondary: {
            main: '#406a8c', // Blue
        },
        warning: {
            main: '#839346',
        },
        error: {
            main: '#b94239', // Red
        },
        background: {
            default: '#303030', // Dark Grey
            paper: '#424242', // Lighter Grey
        },
        text: {
            primary: '#ffffff', // White
            secondary: '#90caf9', // Light Blue
        },
    },
});

export default darkTheme;