import axios from "axios";
import {IDrinkLiterPerOrders} from "../../Entities/DrinkLiterPerOrders";


export const SaveDrinkLiterPerOrder = async (drinkOrders: IDrinkLiterPerOrders[]) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    for (const drinkOrder of drinkOrders) {
        await axios.post(`${backendUrl}/drinkOrders`, drinkOrder, {
            headers: {
                Authorization: `${localStorage.getItem('jwtToken')}`
            }
        }).then(
            (response: any) => {
                return response.data;
            }
        );
    }
}