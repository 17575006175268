// @ts-nocheck
import * as React from 'react';
import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {styled} from '@mui/system';


const CustomDialog = styled(Dialog)({
    '& .MuiBackdrop-root': {
        backgroundColor: 'transparent'
    },
    '& .MuiPaper-root': {
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        margin: 0,
        width: '100%',
        maxWidth: '500px',
        borderRadius: '8px 8px 0 0',
        pointerEvents: 'auto',
    }
});

const CookiesRequestDialog = (props: {
    cookiesAllowed: boolean,
    setCookiesAllowed: (cookiesAllowed: boolean) => void
}) => {

    const {cookiesAllowed, setCookiesAllowed} = props;

    const handleCookiesAllowed = () => {
        setCookiesAllowed(true);
        localStorage.setItem('cookiesAllowed', 'true');
    }

    const handleCookiesNotAllowed = () => {
        setCookiesAllowed(false);
        localStorage.setItem('cookiesAllowed', 'false');
    }

    return (
        <CustomDialog
            open={localStorage.getItem('cookiesAllowed') === null}
            hideBackdrop
            disableEnforceFocus
            disablePortal
        >
            <DialogTitle>
                Cookies
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Hey, wir verwenden ein paar leckere Cookies, bist du damit einverstanden, dass wir ein paar Cookies bei dir speichern?
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}>
                    <Button onClick={handleCookiesAllowed} fullWidth
                            sx={{
                                color: 'white',
                                backgroundColor: '#497531',
                                '&:hover': {
                                    backgroundColor: '#5e8c3a'
                                }
                            }}>
                        Gönn dir!
                    </Button>
                    <Button onClick={handleCookiesNotAllowed} fullWidth
                            sx={{
                                color: 'white',
                                backgroundColor: '#772828',
                                '&:hover': {
                                    backgroundColor: '#a52b2b'
                                }
                            }}>
                        Ich mag keine Cookies!
                    </Button>
                </Box>
            </DialogContent>
        </CustomDialog>
    );

}

export default CookiesRequestDialog;