//@ts-nocheck
import axios from "axios";
import {IDrink} from "../../Entities/Drink";
import {IUser} from "../../Entities/User";
import {IDrinkLiterPerOrders} from "../../Entities/DrinkLiterPerOrders";
import {IDrinkExtraOption} from "../../Entities/DrinkExtraOption";


export const UpdateDrink = async (drink: IDrink) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';

    return await axios.put(`${backendUrl}/drinks/${drink.id}`, drink, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    })
        .then((response: any) => {
            const drinkLiterPerOrders: IDrinkLiterPerOrders[] = response.data["drinkLiterPerOrders"].map((order: any) => {
                return {
                    id: order["id"],
                    drink: order["drink"],
                    additionalDrink: order["additionalDrink"],
                    literPerOrder: order["literPerOrder"],
                }
            });

            const drinkExtraOptions: IDrinkExtraOption[] = response.data["drinkExtraOptions"].map((option: any) => {
                const drinkExtraOption: IDrinkExtraOption = {
                    id: option["id"],
                    name: option["name"],
                    price: option["price"],
                    defaultOption: option["defaultOption"],
                }
                return drinkExtraOption;
            });

            const drink: IUser = {
                id: response.data["id"],
                name: response.data["name"],
                category: response.data["category"],
                description: response.data["description"],
                refPrice: response.data["refPrice"],
                price: response.data["price"],
                image: response.data["image"],
                drinkExtraOptions: drinkExtraOptions,
                literPerOrder: response.data["literPerOrder"],
                drinkLiterPerOrders: drinkLiterPerOrders,
            }
            return drink;
        }
    );
}