// @ts-nocheck

import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {IUser} from "../Entities/User";
import {
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KitchenIcon from '@mui/icons-material/Kitchen';
import EuroIcon from '@mui/icons-material/Euro';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CelebrationIcon from '@mui/icons-material/Celebration';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';


const HomeStaff = (props: { user: IUser, setSelectedPage: (p: string) => void }) => {

    const {user, setSelectedPage} = props;
    const navigate = useNavigate();

    const navigationCards = [
        {title: 'Getränke', icon: <LocalBarIcon/>, path: '/drinks'},
        {title: 'Verkäufe', icon: <ShoppingCartIcon/>, path: '/bills'},
        {title: 'Kunden', icon: <FaceRetouchingNaturalIcon/>, path: '/customers'},
        {title: 'Lager', icon: <KitchenIcon/>, path: '/storage'},
        {title: 'Umsatz', icon: <EuroIcon/>, path: '/revenue'},
        {title: 'Events', icon: <CelebrationIcon/>, path: '/events'},
        {title: 'Öffnungszeiten', icon: <QueryBuilderIcon/>, path: '/openingHours'},
    ];

    React.useEffect(() => {
        if (user === null) {
            navigate('/login');
        }
    }, []);

    return (
        <Box sx={{
            width: '100%',
            height: '92vh',
            overflow: 'auto',
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '28%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            <Typography variant="h3" sx={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                Willkommen {user !== null ? user.username : "Gast"}
            </Typography>
            <Typography variant="h6" sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px'
            }}>
                Du kannst diese Navigation, oder die Topbar verwenden.
            </Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                {navigationCards.map((card, index) => (
                    <Card key={index} sx={{maxWidth: 500, m: 2}} onClick={() => setSelectedPage(card.title)}>
                        <CardActionArea onClick={() => navigate(card.path)}>
                            <CardContent>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    {card.icon}
                                    <Typography variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    Hier können Sie {card.title} verwalten.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
            <Typography variant="h5" sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px'
            }}>
                Im folgenden werden Meldungen zum Server angezeigt, falls es welche gibt, wie z.B. Wartungsarbeiten.
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px'
            }}>
                <Card key={999} sx={{
                    width: "80%",
                    m: 2,
                    boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',
                    backgroundColor: '#e5d699'
                }}>
                    <CardActionArea>
                        <CardContent>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <EngineeringIcon/>
                                <Typography variant="h5" component="div">
                                    Wartungsarbeiten
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                Der Server wird regelmäßig jede Woche Montag von 14:00 bis 16:00 Uhr gewartet.
                                Speichere deine Daten rechtzeitig ab.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Accordion sx={{
                    width: '80%',
                }}>
                    <AccordionSummary sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'rgba(219,229,153,0.8)',
                        color: 'black',
                        alignContent: 'center',
                    }}>
                        <Typography sx={{
                            fontWeight: 'bold',
                            alignContent: 'center',
                            textAlign: 'center',
                        }}>
                            <KeyboardDoubleArrowDownIcon/>
                            Update Nachrichten
                            <KeyboardDoubleArrowDownIcon/>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        backgroundColor: 'rgba(219,229,153,0.37)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Card key={3} sx={{
                            width: "80%",
                            m: 2,
                            boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',
                            backgroundColor: '#dbe599'
                        }}>
                            <CardActionArea>
                                <CardContent>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <TipsAndUpdatesIcon/>
                                        <Typography variant="h5" component="div">
                                            Update #3
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        <p>Folgende Änderungen wurden vorgenommen:</p>
                                        <p style={{fontWeight: 'bold'}}>Funktionen</p>
                                        <p>1. Lager funktion für Getränke wurde implementiert.</p>
                                        <p>2. Mitarbeiter verkäufe wurden an ein paar stellen, speziel an den schnellverkäufen, optimiert.</p>
                                        <p>3. API-Requests wurden optimiert und performanter gestaltet.</p>
                                        <p>4. Verknüpfungen von Getränken in Bezug auf andere Getränke (Mischgetränke) wurde implementiert.</p>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card key={2} sx={{
                            width: "80%",
                            m: 2,
                            boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',
                            backgroundColor: '#dbe599'
                        }}>
                            <CardActionArea>
                                <CardContent>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <TipsAndUpdatesIcon/>
                                        <Typography variant="h5" component="div">
                                            Update #2
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        <p>Folgende Änderungen wurden vorgenommen:</p>
                                        <p style={{fontWeight: 'bold'}}>Funktionen</p>
                                        <p>1. Man kann nun Kunden ausblenden, die bereits alles bezahlt haben (Standard:
                                            Kunden
                                            werden ausgeblendet, die alles bezahlt haben).</p>
                                        <p>2. Standardmäßig ist immer der heutige Tag im Kundenfenster ausgewählt.</p>
                                        <p>3. Man kann in einer Bestellung vom heutigen Tag keine doppelten Getränke
                                            hinzufügen.</p>
                                        <p style={{fontWeight: 'bold'}}>Design</p>
                                        <p>1. Buttons mit "Bearbeiten", "Löschen" wurden durch Icons ersetzt, um Platz
                                            zu sparen.</p>
                                        <p>2. In den Bestellungen, werden nur noch die Uhrzeiten dargestellt.</p>
                                        <p>3. Weitere Auflösungseinstellungen wurden vorgenommen.</p>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card key={1} sx={{
                            width: "80%",
                            m: 2,
                            boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',
                            backgroundColor: '#dbe599'
                        }}>
                            <CardActionArea>
                                <CardContent>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <TipsAndUpdatesIcon/>
                                        <Typography variant="h5" component="div">
                                            Update #1
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        <p>Folgende Änderungen wurden vorgenommen:</p>
                                        <p style={{fontWeight: 'bold'}}>Funktionen</p>
                                        <p>1. Die Bilder der Getränke sind nur noch in der Detailansicht sichtbar.</p>
                                        <p>2. Die Verkaufsübersicht zeigt nun die gesamten Einnahmen korrekt an.</p>
                                        <p>3. Die Kundenübersicht wurde angepasst:</p>
                                        <p>3.1. Kunden die alles bezahlt haben werden grün markiert.</p>
                                        <p>3.2. Es gibt nun einen "Alles Bezahlt?" Button, der alle Rechnungen eines
                                            Kunden als
                                            bezahlt markiert.</p>
                                        <p>3.3. Das aufklappbare "Accordion" wurde von "Bestellungen" zu aktuellem
                                            Kundennamen
                                            abgeändert.</p>
                                        <p>3.4. Der Benutzer muss bei einem neuen Kunden direkt den Namen eingeben,
                                            anstatt Name
                                            eingabe + Button zum Speichern.</p>
                                        <p>4. Getränk löschen befindet sich nun in der Detailansicht des getränks.</p>
                                        <p>5. Auflösung und Elemente der Anwendung wurden angepasst.</p>
                                        <p style={{fontWeight: 'bold'}}>Design</p>
                                        <p>1. Die Farben der "Hinzufügen" Buttons (Unten rechts) wurden auf Gold/Gelb
                                            abgeändert.</p>
                                        <p>2. Andere blauen Buttons wurden zu grün abgeändert.</p>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
}

export default HomeStaff;