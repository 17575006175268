// @ts-nocheck
import {ICustomer} from "../../Entities/Customer";
import axios from "axios";
import {IOrder} from "../../Entities/Order";
import {IDrink} from "../../Entities/Drink";


export const GetAllCustomers = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/customers`, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            const customers: ICustomer[] = [];
            if (Array.isArray(response.data)) {
                response.data.forEach((customer: any) => {
                    customers.push({
                        id: customer["id"],
                        name: customer["name"]
                    });
                });
            }
            return customers;
        }
    );
}