// @ts-nocheck
import {createTheme, ThemeProvider} from '@mui/material/styles';

import * as React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import HomeStaff from "./Pages/HomeStaff";
import Drinks from "./Pages/Drinks";
import TopBar from "./Components/TopBar";
import {GetAllDrinks} from "./Services/DrinksServices/GetAllDrinks";
import {IDrink} from "./Entities/Drink";
import Billings from "./Pages/Billings";
import {GetAllBills} from "./Services/BillsServices/GetAllBills";
import {IBill} from "./Entities/Bill";
import {GetAllDrinkStorages} from "./Services/DrinkStorageServices/GetAllDrinkStorages";
import {IDrinkStorage} from "./Entities/DrinkStorage";
import DrinkStorage from "./Pages/DrinkStorage";
import {IUser} from "./Entities/User";
import Login from "./Pages/Login";
import Customers from "./Pages/Customers";
import {GetAllCustomers} from "./Services/CustomerServices/GetAllCustomers";
import {IOrder} from "./Entities/Order";
import {ICustomer} from "./Entities/Customer";
import {GetAllOrders} from "./Services/OrderServices/GetAllOrders";
import UserButton from "./Components/UserButton";
import HomeGuest from "./Pages/HomeGuest";
import Sales from "./Pages/Sales";
import CookiesRequestDialog from "./Components/CookiesRequestDialog";
import {IEvent} from "./Entities/Event";
import {GetAllEvents} from "./Services/EventServices/GetAllEvents";
import Events from "./Pages/Events";
import {IOpeningHour} from "./Entities/OpeningHour";
import {IEmployeeShift} from "./Entities/EmployeeShift";
import BusinessHours from "./Pages/BusinessHours";
import {GetAllRegularDays} from "./Services/BusinessHoursServices/GetAllRegularDays";
import {IExceptionDay} from "./Entities/ExceptionDay";
import {GetAllExceptionDays} from "./Services/BusinessHoursServices/GetAllExceptionDays";
import {GetAllEmployeeShifts} from "./Services/EmployeeShiftsServices/GetAllEmployeeShifts";

import lightTheme from './Themes/LightTheme';
import darkTheme from "./Themes/DarkTheme";

function App() {

    const [user, setUser] = React.useState<IUser | null>(
        localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    );
    const [selectedPage, setSelectedPage] = React.useState<String | null>(null);
    const [drinks, setDrinks] = React.useState<IDrink[]>([]);
    const [billings, setBillings] = React.useState<IBill[]>([]);
    const [drinkStorages, setDrinkStorages] = React.useState<IDrinkStorage[]>([]);
    const [customers, setCustomers] = React.useState<ICustomer[]>([]);
    const [orders, setOrders] = React.useState<IOrder[]>([]);
    const [events, setEvents] = React.useState<IEvent[]>([]);
    const [openingHours, setOpeningHours] = React.useState<IOpeningHour[]>([]);
    const [employeeShifts, setEmployeeShifts] = React.useState<IEmployeeShift[]>([]);
    const [exceptionDays, setExceptionDays] = React.useState<IExceptionDay[]>([]);
    const [theme, setTheme] = React.useState<'light' | 'dark'>(localStorage.getItem('theme') as 'light' | 'dark');

    const [cookiesAllowed, setCookiesAllowed] = React.useState<boolean | null>(
        getBooleanFromLocalStorage('cookiesAllowed')
    );

    React.useEffect(() => {
        if (user === null || user === undefined) {
            localStorage.clear();
            localStorage.setItem('theme', 'light');
        }

        updateElements();
    }, []);

    React.useEffect(() => {
        updateElements();
    }, [user]);

    const onChangeTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    }

    function getBooleanFromLocalStorage(key) {
        const value = localStorage.getItem(key);
        if (value === null) {
            return null; // Item does not exist in localStorage
        }

        // Check if the value is a valid boolean string
        if (value === 'true') {
            return true;
        } else if (value === 'false') {
            return false;
        } else {
            return null;
        }
    }

    const updateElements = () => {
        GetAllDrinks(false).then(drinks => {
            setDrinks(drinks.filter(drink => drink.id !== 0));
        }).finally(() => {
            if (user === null) {
                return;
            }
            GetAllBills().then((bills) => {
                setBillings(bills);
            }).finally(() => {
                GetAllDrinkStorages().then((drinkStorages) => {
                    setDrinkStorages(drinkStorages);
                }).finally(() => {
                    GetAllCustomers().then(customers => {
                        setCustomers(customers);
                    }).finally(() => {
                        GetAllOrders().then(orders => {
                            setOrders(orders);
                        }).finally(() => {
                            GetAllEvents().then(events => {
                                setEvents(events);
                            }).finally(() => {
                                GetAllRegularDays().then(openingHours => {
                                    setOpeningHours(openingHours);
                                }).finally(() => {
                                    GetAllExceptionDays().then(exceptionDays => {
                                        setExceptionDays(exceptionDays);
                                    }).finally(() => {
                                        GetAllEmployeeShifts().then(employeeShifts => {
                                            setEmployeeShifts(employeeShifts);
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    const updateBills = (newBills) => {
        setBillings(newBills);
    }

    const updateOrders = (newOrders) => {
        setOrders(newOrders);
    }

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <BrowserRouter>
                <TopBar user={user} setUser={setUser} selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}/>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <UserButton user={user} setUser={setUser}/>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '80px'}}>
                        <Routes>
                            <Route path="/"
                                   element={user !== null && (user.role.includes("A") || user.role.includes("T")) ?
                                       <HomeStaff user={user} setSelectedPage={setSelectedPage}/> :
                                       <HomeGuest user={user} setSelectedPage={setSelectedPage}/>}/>
                            <Route path="/login" element={<Login user={user} cookiesAllowed={cookiesAllowed}/>}/>
                            <Route path="/drinks" element={<Drinks drinks={drinks} setDrinks={setDrinks} user={user}
                                                                   setBillings={setBillings}/>}/>
                            <Route path="/bills"
                                   element={<Billings bills={billings} setBills={updateBills} drinks={drinks} orders={orders} setOrders={updateOrders}/>}/>
                            <Route path="/customers"
                                   element={<Customers user={user} customers={customers} setCustomers={setCustomers}
                                                       orders={orders}
                                                       setOrders={setOrders} drinks={drinks}/>}/>
                            <Route path="/storage" element={<DrinkStorage user={user} storage={drinkStorages}
                                                                          setStorage={setDrinkStorages}
                                                                          drinks={drinks}/>}/>
                            <Route path="/sales" element={<Sales user={user} bills={billings} orders={orders} drinks={drinks}/>}/>
                            <Route path="/events" element={<Events user={user} events={events} setEvents={setEvents}/>}/>
                            <Route path="/openingHours" element={<BusinessHours user={user}
                                                                                openingHours={openingHours}
                                                                                setOpeningHours={setOpeningHours}
                                                                                employeeShifts={employeeShifts}
                                                                                setEmployeeShifts={setEmployeeShifts}
                                                                                events={events}
                                                                                setSelectedPage={setSelectedPage}
                                                                                exceptionDays={exceptionDays}
                                                                                setExceptionDays={setExceptionDays}
                            />}/>
                        </Routes>
                    </div>
                </div>
                <CookiesRequestDialog cookiesAllowed={cookiesAllowed} setCookiesAllowed={setCookiesAllowed} />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
