// @ts-nocheck
// process.env.REACT_APP_API_URL = http://localhost:8080
import {IDrinkStorage} from "../../Entities/DrinkStorage";
import axios, {AxiosResponse} from "axios";
import {IDrink} from "../../Entities/Drink";
import {GetDrinkById} from "../DrinksServices/GetDrinkById";

export const GetAllDrinkStorages = async (): Promise<AxiosResponse<IDrinkStorage[]>> => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/drinkStorages`, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            const drinkStorages: IDrinkStorage[] = [];
            response.data.forEach(async (drinkStorage: any) => {
                let drink: IDrink
                if (typeof drinkStorage["drink"] === "number") {
                    drink = await GetDrinkById(drinkStorage["drink"]);
                } else {
                    drink = {
                        id: drinkStorage["drink"]["id"],
                        name: drinkStorage["drink"]["name"],
                        price: drinkStorage["drink"]["price"],
                        refPrice: drinkStorage["drink"]["refPrice"],
                        description: drinkStorage["drink"]["description"],
                        image: drinkStorage["drink"]["image"],
                        drinkExtraOptions: drinkStorage["drink"]["drinkExtraOptions"],
                        literPerOrder: drinkStorage["drink"]["literPerOrder"],
                        drinkLiterPerOrders: drinkStorage["drink"]["drinkLiterPerOrders"],
                        category: drinkStorage["drink"]["category"]
                    }
                }

                drinkStorages.push({
                    id: drinkStorage["id"],
                    name: drinkStorage["name"],
                    price: drinkStorage["price"],
                    drink: drink,
                    literPerBottle: drinkStorage["literPerBottle"],
                    inBoxes: drinkStorage["inBoxes"],
                    boxQuantity: drinkStorage["boxQuantity"],
                    quantityPerBox: drinkStorage["quantityPerBox"],
                    currentBoxQuantity: drinkStorage["currentBoxQuantity"],
                    currentLiterQuantity: drinkStorage["currentLiterQuantity"],
                    warningQuantity: drinkStorage["warningQuantity"]
                });
            });
            return drinkStorages;
        }
    );
}