// @ts-nocheck

import * as React from 'react';
import {Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {CheckMailAvailability} from "../Services/UserServices/CheckMailAvailability";
import {RegisterUser} from "../Services/UserServices/RegisterUser";
import {IUser} from "../Entities/User";


const RegisterAccountDialog = (props: { openRegister: boolean, setOpenRegister: (value: boolean) => void }) => {

    const {openRegister, setOpenRegister} = props;

    const [username, setUsername] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [pwd, setPwd] = React.useState<string>("");
    const [pwdRepeat, setPwdRepeat] = React.useState<string>("");

    const [error, setError] = React.useState<string>("");

    const registerAccount = () => {
        // Check if mail is valid
        const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!mailRegex.test(email)) {
            setError("E-Mail ist nicht gültig!");
            return;
        }

        const tempUser: IUser = {
            id: 0,
            username: "",
            email: email,
            password: "",
            image: "",
            role: "G",
            confirmedMail: false
        }
        CheckMailAvailability(tempUser).then((response) => {
            if (pwd !== pwdRepeat) {
                setError("Passwörter stimmen nicht überein!");
            } else if (pwd.length < 8) {
                setError("Passwort muss mindestens 8 Zeichen lang sein!");
            } else if (!response) {
                setError("E-Mail bereits vergeben!");
            } else {
                // Register User
                RegisterUser(username, email, pwd).then((response) => {
                    // Send register mail to user
                    setOpenRegister(false);
                });
            }
        });
    }

    return (
        <Box>
            <Dialog open={openRegister} onClose={() => setOpenRegister(false)}>
                <DialogTitle>
                    Neues Konto registrieren
                </DialogTitle>
                <DialogContent>
                    <TextField label="Benutzername" variant="outlined" fullWidth
                               onChange={(event) => setUsername(event.target.value)}
                               sx={{
                                   marginTop: 2,
                                   marginBottom: 2
                               }}/>
                    <TextField label="E-Mail" variant="outlined" fullWidth
                               onChange={(event) => setEmail(event.target.value)}/>
                    <TextField label="Passwort" variant="outlined" type="password" fullWidth
                               onChange={(event) => setPwd(event.target.value)}
                               sx={{
                                   marginTop: 2,
                                   marginBottom: 2
                               }}/>
                    <TextField label="Passwort wiederholen" variant="outlined" type="password" fullWidth
                               onChange={(event) => setPwdRepeat(event.target.value)}/>
                    <Typography color="error">
                        {error}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={registerAccount} fullWidth>Registrieren</Button>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default RegisterAccountDialog;