// @ts-nocheck
import axios, {AxiosResponse} from "axios";
import {IBill} from "../../Entities/Bill";


export const GetAllBills = async (): Promise<AxiosResponse<IBill>> => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/bills`, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then((response) => {
        const bills: IBill[] = [];
        response.data.forEach((bill: any) => {
            bills.push({
                id: bill["id"],
                date: bill["date"],
                drinkId: bill["drinkId"],
                refPrice: bill["refPrice"],
                price: bill["price"],
                quantity: bill["quantity"],
                userId: bill["userId"],
            });
        });
        return bills;
    })
}