// @ts-nocheck
import {IUser} from "../../Entities/User";
import axios, {AxiosResponse} from "axios";


export const UpdateUser = async (user: IUser): Promise<AxiosResponse<IUser>> => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.put(`${backendUrl}/users`, user, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: AxiosResponse<IUser>) => {
            return response.data;
        }
    )
}