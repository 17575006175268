// @ts-nocheck
import {IUser} from "../Entities/User";
import React from "react";
import {IBill} from "../Entities/Bill";
import {IOrder} from "../Entities/Order";
import {GetAllUsers} from "../Services/UserServices/GetAllUsers";

import {
    Accordion,
    Avatar,
    Box,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableRow,
    TableBody, TableCell, TableHead, Paper, TableContainer, TablePagination
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {IDrink} from "../Entities/Drink";


const Sales = (props: { user: IUser, bills: IBill[], orders: IOrder[], drinks: IDrink[] }) => {

    const { user, bills, orders, drinks} = props;

    const [users, setUsers] = React.useState<IUser[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        GetAllUsers().then((users) => {
            setUsers(users);
        });
    }, []);

    const userDict = users.reduce((acc, user) => {
        const userOrders = orders.filter(order => order.userId === user.id);
        const userBills = bills.filter(bill => bill.userId === user.id);
        acc[user.id] = { orders: userOrders, bills: userBills };
        return acc;
    }, {});

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{
            width: '100vw',
            height: '80vh',
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '25%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            {users.map(u => {
                const userOrders = userDict[u.id]?.orders || [];
                const userBills = userDict[u.id]?.bills || [];

                const ordersByDay = userOrders.reduce((acc, order) => {
                    const orderDate = order.dateTime.toString().split("T")[0];
                    if (!acc[orderDate]) {
                        acc[orderDate] = [];
                    }
                    acc[orderDate].push(order);
                    return acc;
                }, {});

                const billsByDay = userBills.reduce((acc, bill: IBill) => {
                    const billDate = bill.date.toString().split("T")[0];
                    if (!acc[billDate]) {
                        acc[billDate] = [];
                    }
                    acc[billDate].push(bill);
                    return acc;
                }, {});

                return (
                    <Accordion key={u.id} sx={{
                        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Avatar alt={u.username} src={u.image} />
                            <Typography sx={{ marginLeft: 2 }}>{u.username}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Kundenverkäufe</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        Object.entries(ordersByDay).map(([date, orders]) => {
                                            const drinkSums = orders.reduce((acc, order) => {
                                                if (!acc[order.drink.name]) {
                                                    acc[order.drink.name] = 0;
                                                }
                                                acc[order.drink.name] += order.sum;
                                                return acc;
                                            }, {});

                                            const totalSum = orders.reduce((acc, order) => {
                                                if (order.status) {
                                                    acc += order.sum;
                                                }
                                                return acc;
                                            }, 0);

                                            return (
                                                <Box key={date}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        borderBottom: 1,
                                                        borderColor: 'rgba(0,0,0,0.2)',
                                                        marginBottom: 1,

                                                    }}>
                                                        <Typography variant="h6">{date}</Typography>
                                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Gesamtumsatz: {totalSum.toFixed(2)}€</Typography>
                                                    </Box>
                                                    <TableContainer component={Paper}>
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" sx={{fontWeight: 'bold'}}>Getränkname</TableCell>
                                                                    <TableCell align="left" sx={{fontWeight: 'bold'}}>Umsatz</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {orders.map((order, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{order.drink.name}</TableCell>
                                                                        <TableCell sx={{
                                                                            color: order.status ? 'green' : 'red',
                                                                        }}>{parseFloat(order.sum).toFixed(2)}€</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            );
                                        })
                                    }
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>Schnellverkäufe</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {

                                        Object.entries(billsByDay).map(([date, bills]) => {
                                            const drinkSums = bills.reduce((acc, bill: IBill) => {
                                                if (!acc[drinks.filter(drink => drink.id === bill.drinkId)[0]?.name]) {
                                                    acc[drinks.filter(drink => drink.id === bill.drinkId)[0]?.name] = 0;
                                                }
                                                acc[drinks.filter(drink => drink.id === bill.drinkId)[0]?.name] += bill.price * bill.quantity;
                                                return acc;
                                            }, {});

                                            const totalSum = bills.reduce((acc, bill: IBill) => {
                                                acc += bill.price * bill.quantity;
                                                return acc;
                                            }, 0);

                                            return(
                                                <Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        borderBottom: 1,
                                                        borderColor: 'rgba(0,0,0,0.2)',
                                                        marginBottom: 1,

                                                    }}>
                                                        <Typography variant="h6">{date}</Typography>
                                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Gesamtumsatz: {totalSum.toFixed(2)}€</Typography>
                                                    </Box>
                                                    <TableContainer component={Paper}>
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" sx={{fontWeight: 'bold'}}>Getränkname</TableCell>
                                                                    <TableCell align="left" sx={{fontWeight: 'bold'}}>Umsatz</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {userBills.map((bill: IBill, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{drinks.filter(drink => drink.id === bill.drinkId)[0]?.name}</TableCell>
                                                                        <TableCell sx={{
                                                                            color: 'green',
                                                                        }}>{parseFloat(bill.price * bill.quantity).toFixed(2)}€</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            );
                                        })
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
}

export default Sales;