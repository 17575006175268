import axios from "axios";
import {UpdateDrink} from "./UpdateDrink";


export const UploadDrinkImage = async (id: number, image: File) => {
    const formData = new FormData();
    formData.append("image", image);
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.put(`${backendUrl}/drinks/${id}/image`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}