// @ts-nocheck
import {Box, Button, Dialog, DialogContent, DialogTitle, FormControl, TextField, Typography} from "@mui/material";
import {IUser} from "../Entities/User";
import React from "react";
import {LoginService} from "../Services/Login/LoginService";
import {useNavigate} from "react-router-dom";
import RegisterAccountDialog from "../Components/RegisterAccountDialog";

const Login = (props: {
    user: IUser,
    cookiesAllowed: boolean,
}) => {

    const {user, cookiesAllowed} = props;
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [employeeId, setEmployeeId] = React.useState<string>('');
    const [openRegister, setOpenRegister] = React.useState<boolean>(false);
    const [asEmployee, setAsEmployee] = React.useState<boolean>(true);
    const [loginError, setLoginError] = React.useState<string>('');

    const navigate = useNavigate();

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email === '' || password === '' || (asEmployee && employeeId === '')) {
            setLoginError('Bitte füllen Sie alle Felder aus!');
            return;
        }
        LoginService(email, password, employeeId, cookiesAllowed).then((response) => {
            if (response) {
                navigate("/");
                window.location.reload();
            } else if (!response) {
                setLoginError('Falsche Anmeldeinformationen!');
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                setLoginError('Falsche Anmeldeinformationen!');
            } else {
                console.error(error);
            }
        });
    };

    return (
        <Box>
            <Dialog open={user === null} style={{backgroundColor: 'white'}}>
                <DialogTitle sx={{
                    color: 'black',
                    textAlign: 'center'
                }}>
                    Willkommen im Bittersüß Staffboard
                    <Typography>
                        Bitte loggen Sie sich ein
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <Button fullWidth onClick={() => {
                            setAsEmployee(true);
                            setLoginError('');
                        }} sx={{
                            backgroundColor: asEmployee ? 'rgba(192,173,109,0.84)' : '#ffffff',
                            color: 'black',
                            "&:hover": {
                                backgroundColor: asEmployee ? 'rgba(192,173,109,0.62)' : 'rgba(255,255,255,0.6)',
                            }
                        }}>
                            Mitarbeiter
                        </Button>
                    </Box>
                    <FormControl>
                        <TextField sx={{marginTop: 2, marginBottom: 2}}
                                   label="E-Mail" type="text"
                                   onChange={(event) => setEmail(event.target.value)}/>
                        <TextField label="Passwort" type="password"
                                   onChange={(event) => setPassword(event.target.value)}/>
                        {asEmployee && <TextField onChange={(event) => setEmployeeId(event.target.value)}
                                                  sx={{marginTop: 2, marginBottom: 2}} label="Mitarbeiter-ID"
                                                  type="text"/>}
                        <Typography sx={{
                            color: 'red',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            {loginError}
                        </Typography>
                        {!asEmployee &&
                            <Typography>
                                Noch keinen Account? <Button disabled
                                                             onClick={() => setOpenRegister(true)}>Registrieren</Button>
                            </Typography>
                        }
                        <Typography>
                            Passwort vergessen? <Button disabled>Passwort zurücksetzen</Button>
                        </Typography>
                        <Button disabled={!asEmployee} type="submit"
                                onClick={(event) => handleLogin(event)}
                        sx={{
                            backgroundColor: 'rgba(192,173,109,0.85)',
                            color: 'black',
                        }}>
                            Einloggen
                        </Button>
                    </FormControl>
                </DialogContent>
            </Dialog>
            {
                openRegister &&
                <RegisterAccountDialog openRegister={openRegister} setOpenRegister={setOpenRegister}/>
            }
        </Box>
    )
}

export default Login;