// @ts-nocheck
import * as React from 'react';
import axios, {AxiosResponse} from "axios";
import {IUser} from "../../Entities/User";


export const LoginService = async (
    email: string,
    password: string,
    employeeId: string,
    cookiesAllowed: boolean
): Promise<AxiosResponse<IUser | null>> =>
{
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/login`, {
        email: email,
        password: password,
        employeeId: employeeId
    }).then((response) => {
        if (response.status === 200) {
            const token = response.headers['authorization'];
            if (token) {
                localStorage.setItem('jwtToken', token.trim());
            } else {
                throw new Error('No Authorization header found!');
            }
            const user: IUser = {
                id: response.data["id"],
                username: response.data["username"],
                email: response.data["email"],
                password: '',
                role: response.data["role"],
                image: response.data["image"],
                employeeId: response.data["employeeId"],
                confirmedMail: response.data["confirmedMail"],
            }
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        } else {
            return null;
        }
    });
}