import axios from "axios";
import {IEvent} from "../../Entities/Event";


export const GetAllEvents = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/events`, {
        headers: {
            'authorization': `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        async (response: any) => {
            const events: IEvent[] = [];
            for (const event of response.data) {
                events.push({
                    id: event["id"],
                    name: event["name"],
                    description: event["description"],
                    location: event["location"],
                    startDateTime: event["startDateTime"],
                    endDateTime: event["endDateTime"],
                });
            }
            return events;
        }
    ).catch((error: any) => {
        console.error(error);
        return [];
    });
}