// @ts-nocheck

import * as React from 'react';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete, Divider,
    Grid,
    IconButton,
    InputAdornment, Switch,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';
import {IDrink} from "../Entities/Drink";
import {SaveDrink} from "../Services/DrinksServices/SaveDrink";
import {useEffect} from "react";
import LocalBarIcon from '@mui/icons-material/LocalBar';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import {Tooltip} from "recharts";
import {SaveDrinkLiterPerOrder} from "../Services/DrinkLiterPerOrderServices/SaveDrinkLiterPerOrder";


const AddDrinkButton = (props: { drinks: IDrink[], setDrinks: (d: IDrink[]) => void }) => {

    const {drinks, setDrinks} = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const [drink, setDrink] = React.useState<IDrink>({
        name: '',
        description: '',
        category: '',
        image: null,
        price: 0,
        refPrice: 0,
        drinkExtraOptions: [],
    });
    const [availableCategories, setAvailableCategories] = React.useState<String[]>([]);
    const [optionCount, setOptionCount] = React.useState(0);
    const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
    const [optionDefault, setOptionDefault] = React.useState(false);
    const [isDrinkOrderAccordionOpen, setIsDrinkOrderAccordionOpen] = React.useState(false);

    useEffect(() => {
        // Get all available categories
        if (drinks.length === 0) {
            return;
        }
        const categories = drinks.map((d) => d.category);
        const uniqueCategories = [...new Set(categories)];
        setAvailableCategories(uniqueCategories);
    }, [drinks]);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const changeDrinkName = (event) => {
        setDrink({
            ...drink,
            name: event.target.value,
        });
    }

    const changeDrinkDescription = (event) => {
        setDrink({
            ...drink,
            description: event.target.value,
        });
    }

    const changeDrinkPrice = (event) => {
        setDrink({
            ...drink,
            price: Number(event.target.value),
        });
    }

    const changeDrinkRefPrice = (event) => {
        setDrink({
            ...drink,
            refPrice: Number(event.target.value),
        });
    }

    const changeDrinkCategory = (event, value) => {
        if (value === null) {
            return
        }
        setDrink({
            ...drink,
            category: value,
        });
    }

    const addOption = () => {
        // Check if there are already options
        if (drink.drinkExtraOptions === null) {
            setDrink({
                ...drink,
                drinkExtraOptions: [],
            });
        } else {
            setDrink({
                ...drink,
                optionPrices: [...drink.drinkExtraOptions, 0],
            });
        }
        setOptionCount(optionCount + 1);
    };

    const addDrink = () => {
        SaveDrink(drink).then(() => {
            setDrinks([...drinks, drink]);
        }).finally(() => {
            if (drink.drinkLiterPerOrders === null || drink.drinkLiterPerOrders === undefined) {
                setIsOpen(false);
                return;
            }
            SaveDrinkLiterPerOrder(drink.drinkLiterPerOrders).then(() => {
                setIsOpen(false);
                setIsDrinkOrderAccordionOpen(false);
            }).finally(() => {
                //window.location.reload();
            })
        });
    }

    const addDrinkOrder = () => {
        if (drink.drinkLiterPerOrders === null || drink.drinkLiterPerOrders === undefined) {
            setDrink({
                ...drink,
                drinkLiterPerOrders: [],
            });
        } else {
            setDrink({
                ...drink,
                drinkLiterPerOrders: [...drink.drinkLiterPerOrders, {
                    drink: drink,
                    additionalDrink: {
                        id: 0,
                        name: "",
                        description: "",
                        price: 0.0,
                        refPrice: 0.0,
                        category: "",
                        image: null,
                        drinkExtraOptions: [],
                        drinkLiterPerOrders: []
                    },
                    literPerOrder: 0
                }],
            });
        }
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 50,
                right: 50,
                zIndex: 99
            }}
        >
            <Fab aria-label="Neues Getränk hinzufügen" onClick={handleClick}
                 sx={{
                     backgroundColor: 'rgb(192,173,109)',
                     boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                     width: '100px',
                     height: '100px',
                 }}>

                <AddIcon/>
                <LocalBarIcon/>
            </Fab>
            <Dialog open={isOpen} onClose={handleClick} fullScreen>
                <DialogTitle>
                    Neues Getränk hinzufügen
                    <IconButton onClick={handleClick} sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        marginBottom: 1,
                    }}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Divider />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name des Getränks"
                        type="text"
                        fullWidth
                        onChange={(e) => changeDrinkName(e)}
                        sx={{
                            overflow: 'auto', // Set overflow to auto
                            marginTop: 4,
                            marginBottom: 2,
                        }}
                    />
                    <Autocomplete
                        freeSolo
                        options={availableCategories}
                        renderInput={(params) => (
                            <TextField {...params} label="Kategorie des Getränks"/>
                        )}
                        onChange={(event, value) => changeDrinkCategory(event, value)}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Beschreibung"
                        multiline
                        rows={8}
                        type="text"
                        fullWidth
                        onChange={(e) => changeDrinkDescription(e)}
                    />
                    <Accordion expanded={isDrinkOrderAccordionOpen}
                               onChange={() => setIsDrinkOrderAccordionOpen(!isDrinkOrderAccordionOpen)}
                               disabled>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography>Bestellungsdetails</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                margin="dense"
                                id="litersPerOrder"
                                label="Liter pro Bestellung"
                                type="number"
                                fullWidth
                                onChange={(e) => setDrink({
                                    ...drink,
                                    literPerOrder: Number(e.target.value),
                                })}
                            />
                            {drink?.drinkLiterPerOrders !== null && drink?.drinkLiterPerOrders !== undefined && Array.from({length: drink.drinkLiterPerOrders.length}, (_, index) => (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                }}>
                                    <Autocomplete
                                        id="drink-name-autocomplete"
                                        options={drinks}
                                        getOptionLabel={(option) => option.name}
                                        value={drink.drinkLiterPerOrders && drink.drinkLiterPerOrders[index].additionalDrink ?
                                            drink.drinkLiterPerOrders[index].additionalDrink : null}
                                        onChange={(e, value) => {
                                            setDrink({
                                                ...drink,
                                                drinkLiterPerOrders: drink.drinkLiterPerOrders.map((order, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...order,
                                                            additionalDrink: value
                                                        }
                                                    }
                                                    return order;
                                                })
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                key={index}
                                                type="text"
                                                label={`Getränk ${index + 1}`}
                                                fullWidth
                                            />
                                        )}
                                        fullWidth
                                    />
                                    <TextField
                                        key={index}
                                        type="number"
                                        label={`Liter pro Bestellung ${index + 1}`}
                                        value={drink.drinkLiterPerOrders !== undefined && drink.drinkLiterPerOrders[index] ? drink.drinkLiterPerOrders[index].literPerOrder : 0}
                                        onChange={(e) => {
                                            const litersPerOrder = Number(e.target.value);
                                            setDrink({
                                                ...drink,
                                                drinkLiterPerOrders: drink.drinkLiterPerOrders.map((order, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...order,
                                                            literPerOrder: litersPerOrder
                                                        }
                                                    }
                                                    return order;
                                                })
                                            });
                                        }}
                                        fullWidth
                                    />
                                    <IconButton onClick={() => {
                                        const newDrinkOrders = drink.drinkLiterPerOrders;
                                        newDrinkOrders.splice(index, 1);
                                        setDrink({
                                            ...drink,
                                            drinkLiterPerOrders: newDrinkOrders,
                                        });
                                    }}>
                                        <CloseIcon sx={{color: 'red'}}/>
                                    </IconButton>
                                </Box>
                            ))}
                            <Button onClick={() => addDrinkOrder()} fullWidth sx={{
                                marginTop: 2,
                                backgroundColor: '#4d8196',
                                color: 'white',
                            }}>+</Button>
                        </AccordionDetails>
                    </Accordion>
                    <TextField
                        margin="dense"
                        id="price"
                        label="Einkaufspreis"
                        type="number"
                        fullWidth
                        defaultValue={0.00}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                        onChange={(e, v) => changeDrinkRefPrice(e, v)}
                    />
                    <TextField
                        margin="dense"
                        id="refPrice"
                        label="Verkaufspreis"
                        type="number"
                        defaultValue={0.00}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                        onChange={(e, v) => changeDrinkPrice(e, v)}
                    />
                    <Accordion expanded={isAccordionOpen} onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            onClick={() => {
                            }}
                            sx={{
                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.35)',
                            }}
                        >
                            <Typography>Weitere Preisoptionen</Typography>
                        </AccordionSummary>
                        <AccordionDetails onClick={() => {
                        }} sx={{
                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                        }}>
                            {drink?.drinkExtraOptions !== null && Array.from({length: optionCount}, (_, index) => (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                }}>
                                    <TextField
                                        key={index}
                                        type="text"
                                        label={`Optionname ${index + 1}`}
                                        value={drink.drinkExtraOptions && drink.drinkExtraOptions[index] ? drink.drinkExtraOptions[index].name : ''}
                                        onChange={(e) => {
                                            const optionName = e.target.value;
                                            setDrink({
                                                ...drink,
                                                drinkExtraOptions: drink.drinkExtraOptions.map((option, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...option,
                                                            name: optionName
                                                        }
                                                    }
                                                    return option;
                                                })
                                            });
                                        }}
                                        fullWidth
                                    />
                                    <TextField
                                        key={index}
                                        type="number"
                                        label={`Optionprice ${index + 1}`}
                                        value={drink.drinkExtraOptions && drink.drinkExtraOptions[index] ? drink.drinkExtraOptions[index].price : 0}
                                        onChange={(e) => {
                                            const optionPrice = Number(e.target.value);
                                            setDrink({
                                                ...drink,
                                                drinkExtraOptions: drink.drinkExtraOptions.map((option, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...option,
                                                            price: optionPrice
                                                        }
                                                    }
                                                    return option;
                                                })
                                            });
                                        }}
                                        fullWidth
                                    />
                                    <Switch aria-label="Standard?" value={optionDefault} onChange={() => {
                                        setOptionDefault(!optionDefault);
                                        setDrink({
                                            ...drink,
                                            drinkExtraOptions: drink.drinkExtraOptions.map((option, i) => {
                                                if (i === index) {
                                                    return {
                                                        ...option,
                                                        defaultOption: !option.defaultOption,
                                                    }
                                                }
                                                return option;
                                            })
                                        });
                                    }}>
                                    </Switch>
                                    <IconButton onClick={() => {
                                        const newOptionPrices = drink.drinkExtraOptions;
                                        newOptionPrices.splice(index, 1);
                                        setDrink({
                                            ...drink,
                                            optionPrices: newOptionPrices,
                                        });
                                        setOptionCount(optionCount - 1);
                                    }}>
                                        <CloseIcon sx={{color: 'red'}}/>
                                    </IconButton>
                                </Box>
                            ))}
                            <Button onClick={() => addOption()} fullWidth sx={{
                                marginTop: 2,
                                backgroundColor: '#4d8196',
                                color: 'white',
                            }}>+</Button>
                        </AccordionDetails>
                    </Accordion>
                    <Button variant="contained" fullWidth={true} onClick={() => addDrink()}
                            sx={{
                                marginTop: 1,
                                backgroundColor: '#678f36',
                                "&:disabled": {
                                    backgroundColor: "#888888"
                                },
                                "&:hover": {
                                    backgroundColor: "#2f7905",
                                    boxShadow: 1
                                }
                    }}>
                        Hinzufügen
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default AddDrinkButton;