import axios from "axios";
import {IEmployeeShift} from "../../Entities/EmployeeShift";



export const AddEmployeeShift = async (employeeShift: IEmployeeShift) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/business_hours/employee_shifts`, employeeShift, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}