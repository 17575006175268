// @ts-nocheck
import * as React from 'react';
import {IBill} from "../Entities/Bill";

import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush} from 'recharts';
import {IDrink} from "../Entities/Drink";
import {Autocomplete, Box, CircularProgress, MenuItem, Select, TextField, Typography} from "@mui/material";
import {IOrder} from "../Entities/Order";
import {GetAllBills} from "../Services/BillsServices/GetAllBills";
import {GetAllOrders} from "../Services/OrderServices/GetAllOrders";
import { useLocation } from 'react-router-dom';


const Billings = (props: {
    bills: IBill[],
    setBills: (b: IBill[]) => void,
    drinks: IDrink[],
    orders: IOrder[],
    setOrders: (o: IOrder[]) => void
}) => {
    const {bills, setBills, drinks, orders, setOrders} = props

    const [data, setData] = React.useState([]);
    const [drinkNames, setDrinkNames] = React.useState<string[]>([]);
    const [drinkFilter, setDrinkFilter] = React.useState<string>("Alle");
    const [selectedDrink, setSelectedDrink] = React.useState<string>("Alle");
    const [totalRevenueForSelectedDate, setTotalRevenueForSelectedDate] = React.useState<number>(0);
    const [selectedDate, setSelectedDate] = React.useState<string>("Alle");
    const [uniqueDates, setUniqueDates] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);

    const location = useLocation();

    React.useEffect(() => {
        updateElements();
    }, [])

    React.useEffect(() => {
        updateElements();
    }, [location.pathname])

    React.useEffect(() => {
        getRevenueForSelectedDate();
        filterDataByDate();
    }, [selectedDate])

    React.useEffect(() => {
        getRevenueForSelectedDrink();
        filterBillByDrink();
    }, [selectedDrink])

    const updateElements = () => {
        setLoading(true);
        Promise.all([GetAllBills(), GetAllOrders()])
            .then(([billsArray, ordersArray]) => {
                setBills(billsArray);
                setOrders(ordersArray);
            })
            .finally(() => setLoading(false));
    };

    const filterBills = () => {
        const chartDataObj: { [key: string]: number, [key: string]: string } = {};
        // Init data. Split data into x = date and y = quantity.
        bills.forEach(bill => {
            const date = bill.date.split("T")[0];
            if (!chartDataObj[date]) {
                chartDataObj[date] = 0;
            }
            chartDataObj[date] += bill.quantity;
        });
        // Also add the orders to the data.
        orders.forEach(order => {
            const date = order.dateTime.split("T")[0];
            if (!chartDataObj[date]) {
                chartDataObj[date] = 0;
            }
            chartDataObj[date] += order.quantity;
        });

        const chartData = Object.keys(chartDataObj).map(date => ({
            xValue: date,
            yValue: chartDataObj[date]
        }));

        setData(chartData);
        setDrinkNames(drinks.map(drink => drink.name));
        setTotalRevenueForSelectedDate(bills.reduce((acc, bill) => bill.price * bill.quantity, 0));
        setUniqueDates(Object.keys(chartDataObj));
    }

    const filterDataByDateAndDrink = () => {
        const chartDataObj: { [key: string]: number, [key: string]: string } = {};
        let filteredBills = [];
        let filteredOrders = [];
        if (selectedDate !== "Alle") {
            filteredBills = bills.filter(bill => bill.date.split("T")[0] === selectedDate);
            filteredOrders = orders.filter(order => order.dateTime.split("T")[0] === selectedDate);
        } else {
            filteredBills = bills;
            filteredOrders = orders;
        }

        if (selectedDrink === "Alle") {
            filteredOrders.forEach(order => {
                const date = order.dateTime.split("T")[0];
                if (!chartDataObj[date]) {
                    chartDataObj[date] = 0;
                }
                chartDataObj[date] += order.quantity;
            });

            filteredBills.forEach(bill => {
                const date = bill.date.split("T")[0];
                if (!chartDataObj[date]) {
                    chartDataObj[date] = 0;
                }
                chartDataObj[date] += bill.quantity;
            });

            const chartData = Object.keys(chartDataObj).map(date => ({
                xValue: date,
                yValue: chartDataObj[date]
            }));

            setData(chartData);
            return;
        }

        const drinkId = getDrinkIdByName(selectedDrink);
        const filteredBillsByDrink = filteredBills.filter(bill => bill.drinkId === drinkId);
        const filteredOrdersByDrink = filteredOrders.filter(order => order.drink.id === drinkId);

        filteredOrdersByDrink.forEach(order => {
            const date = order.dateTime.split("T")[0];
            if (!chartDataObj[date]) {
                chartDataObj[date] = 0;
            }
            chartDataObj[date] += order.quantity;
        });

        filteredBillsByDrink.forEach(bill => {
            const date = bill.date.split("T")[0];
            if (!chartDataObj[date]) {
                chartDataObj[date] = 0;
            }
            chartDataObj[date] += bill.quantity;
        });

        const chartData = Object.keys(chartDataObj).map(date => ({
            xValue: date,
            yValue: chartDataObj[date]
        }));

        setData(chartData);
    }

    const filterDataByDate = () => {
        const chartDataObj: { [key: string]: number, [key: string]: string } = {};
        let filteredBills = [];
        let filteredOrders = [];
        if (selectedDate !== "Alle") {
            filteredBills = bills.filter(bill => bill.date.split("T")[0] === selectedDate);
            filteredOrders = orders.filter(order => order.dateTime.split("T")[0] === selectedDate);
        } else {
            filteredBills = bills;
            filteredOrders = orders;
        }

        if (filteredOrders.length > 0) {
            filteredOrders.forEach(order => {
                const date = order.dateTime.split("T")[0];
                if (!chartDataObj[date]) {
                    chartDataObj[date] = 0;
                }
                chartDataObj[date] += order.quantity;
            });
        }

        if (filteredBills.length > 0) {
            filteredBills.forEach(bill => {
                const date = bill.date.split("T")[0];
                if (!chartDataObj[date]) {
                    chartDataObj[date] = 0;
                }
                chartDataObj[date] += bill.quantity;
            });
        }

        const chartData = Object.keys(chartDataObj).map(date => ({
            xValue: date,
            yValue: chartDataObj[date]
        }));

        setData(chartData);
    }

    const getDrinkIdByName = (name: string): string | number | undefined => {
        if (name === "Alle") {
            return "Alle";
        }
        const drink = drinks.find(drink => drink.name === name);
        return drink?.id;
    }

    const getRevenueForSelectedDate = () => {
        if (selectedDate === "Alle") {
            const billsRevenue = bills.reduce((acc, bill) => acc + bill.price, 0);
            // Add all orders that are completed, get the sum for each order and add them together.
            const ordersRevenue = orders.filter(order => order.status).reduce((acc, order) => acc + order.sum, 0);
            setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
            return;
        }
        const billsRevenue = bills.filter(bill => bill.date.split("T")[0] === selectedDate)
            .reduce((acc, bill) => bill.price, 0);
        const ordersRevenue = orders.filter(order => order.dateTime.split("T")[0] === selectedDate
            && order.status)
            .reduce((acc, order) => acc + order.sum, 0);
        setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
    }

    const getRevenueForSelectedDrink = () => {
        const drinkId = getDrinkIdByName(selectedDrink);
        if (drinkId === undefined) {
            return;
        }
        if (drinkId === "Alle") {
            const billsRevenue = bills.reduce((acc, bill) => acc + bill.price, 0);
            // Add all orders that are completed, get the sum for each order and add them together.
            const ordersRevenue = orders.filter(order => order.status).reduce((acc, order) => acc + order.sum, 0);
            setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
            return;
        }
        const billsRevenue = bills.filter(bill => bill.drinkId === drinkId).reduce((acc, bill) => bill.price, 0);
        const ordersRevenue = orders.filter(order => order.drink.id === drinkId && order.status).reduce((acc, order) => acc + order.sum, 0);
        setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
    }

    const getRevenueForSelectedDateAndDrink = () => {
        const drinkId = getDrinkIdByName(selectedDrink);
        if (drinkId === undefined) {
            return;
        }
        if (drinkId === "Alle") {
            const billsRevenue = bills.filter(bill => bill.date.split("T")[0] === selectedDate)
                .reduce((acc, bill) => bill.price, 0);
            const ordersRevenue = orders.filter(order => order.dateTime.split("T")[0] === selectedDate
                && order.status)
                .reduce((acc, order) => acc + order.sum, 0);
            setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
            return;
        }
        const billsRevenue = bills.filter(bill => bill.date.split("T")[0] === selectedDate && bill.drinkId === drinkId)
            .reduce((acc, bill) => bill.price, 0);
        const ordersRevenue = orders.filter(order => order.dateTime.split("T")[0] === selectedDate
            && order.drink.id === drinkId && order.status)
            .reduce((acc, order) => acc + order.sum, 0);
        setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
    }

    const filterBillByDrink = () => {
        const chartDataObj: { [key: string]: number, [key: string]: string } = {};
        if (selectedDrink === "Alle") {
            bills.forEach(bill => {
                const date = bill.date.split("T")[0];
                if (!chartDataObj[date]) {
                    chartDataObj[date] = 0;
                }
                chartDataObj[date] += bill.quantity;
            });
            // Also add the orders to the data.
            orders.forEach(order => {
                const date = order.dateTime.split("T")[0];
                if (!chartDataObj[date]) {
                    chartDataObj[date] = 0;
                }
                chartDataObj[date] += order.quantity;
            });

            const chartData = Object.keys(chartDataObj).map(date => ({
                xValue: date,
                yValue: chartDataObj[date]
            }));
            setData(chartData);
            return;
        }
        const drinkId = getDrinkIdByName(selectedDrink);
        const filteredBills = bills.filter(bill => bill.drinkId === drinkId);
        const filteredOrders = orders.filter(order => order.drink.id === drinkId);

        filteredOrders.forEach(order => {
            const date = order.dateTime.split("T")[0];
            if (!chartDataObj[date]) {
                chartDataObj[date] = 0;
            }
            chartDataObj[date] += order.quantity;
        });

        filteredBills.forEach(bill => {
            const date = bill.date.split("T")[0];
            if (!chartDataObj[date]) {
                chartDataObj[date] = 0;
            }
            chartDataObj[date] += bill.quantity;
        });

        const chartData = Object.keys(chartDataObj).map(date => ({
            xValue: date,
            yValue: chartDataObj[date]
        }));
        setData(chartData);
    }

    const getRevenueForAll = () => {
        const billsRevenue = bills.reduce((acc, bill) => acc + bill.price, 0);
        // Add all orders that are completed, get the sum for each order and add them together.
        const ordersRevenue = orders.filter(order => order.status).reduce((acc, order) => acc + order.sum, 0);
        setTotalRevenueForSelectedDate(billsRevenue + ordersRevenue);
    }

    const ChangeDateDisplayFormat = (date: string) => {
        const dateArray = date.split('-');
        return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
    }

    return (
        <Box sx={{
            width: '100%',
            height: '85%',
            overflow: 'auto',
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '25%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            {
                loading ?
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        marginTop: {
                            xs: '30%', // Für kleine Geräte
                            sm: '25%', // Für mittlere Geräte
                            md: '20%', // Für große Geräte
                            lg: '10%', // Für sehr große Geräte
                        },
                    }}>
                        <CircularProgress size="10rem" sx={{
                            color: 'rgb(192,173,109)',
                        }} />
                    </Box>
                    :
                    <>
                        <Box sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <Typography sx={{
                                fontSize: '2em',
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: 'center',
                            }}>
                                Umsatzübersicht
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                width: '50%',
                            }}>
                                <Typography variant="h6">
                                    Nach Getränk filtern
                                </Typography>
                                <Select
                                    sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        width: '100%',
                                    }}
                                    value={selectedDrink}
                                    onChange={(event) => {
                                        setSelectedDrink(event.target.value);
                                    }}>
                                    <MenuItem value={"Alle"}>Alle</MenuItem>
                                    {drinkNames.map((drinkName, index) => (
                                        <MenuItem key={index} value={drinkName}>{drinkName}</MenuItem>
                                    ))}
                                </Select>
                                <Typography variant="h6">
                                    Nach Tag filtern
                                </Typography>
                                <Select
                                    value={selectedDate}
                                    onChange={(event) => {
                                        setSelectedDate(event.target.value);
                                    }}>
                                    <MenuItem value="Alle">Alle</MenuItem>
                                    <MenuItem value={new Date().toISOString().split('T')[0]}>Heute</MenuItem>
                                    {uniqueDates.map(date => (
                                        <MenuItem value={date}>{date}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        <ResponsiveContainer width="100%" height="60%" id="chart-container">
                            <LineChart data={data}>
                                <CartesianGrid strokeDasharray="1 1"/>
                                <XAxis dataKey="xValue"/>
                                <YAxis dataKey="yValue"/>
                                <Tooltip contentStyle={{
                                    backgroundColor: "rgb(213,213,213)",
                                    border: "1px solid #f5f5f5",
                                    borderRadius: "0px",
                                    boxShadow: "0 2px 3px 0 rgba(0,0,0,0.1)",
                                    color: "warning",
                                }}/>
                                <Legend/>
                                <Line name={selectedDrink}
                                      type={"monotone"}
                                      dataKey="yValue"
                                      strokeWidth={2}/>
                            </LineChart>
                        </ResponsiveContainer>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            width: '100%',
                        }}>
                            <Typography
                                sx={{
                                    marginTop: 2,
                                    fontSize: '1.5em',
                                }}
                            >
                                Gesamtumsatz
                                für {selectedDate === "Alle" ? "alle Tage" : "den " + ChangeDateDisplayFormat(selectedDate)}: {' '}
                                <Typography
                                    sx={{
                                        color: 'primary',
                                        fontSize: '2em',
                                        color: '#257a40',
                                    }}>
                                    {
                                        totalRevenueForSelectedDate.toFixed(2)
                                    } €
                                </Typography>
                            </Typography>
                        </Box>
                    </>
            }
        </Box>
    );
}

export default Billings;