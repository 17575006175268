// @ts-nocheck
import axios from "axios";


export const RegisterUser = async (username: string, email: string, pwd: string) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/users`, {
        username: username,
        email: email,
        password: pwd,
        image: null,
        role: "G"
    }, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}