// @ts-nocheck
import {
    Box,
    Button, IconButton, MenuItem, Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Typography
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import {IUser} from "../Entities/User";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from "@mui/icons-material/Delete";
import {IEvent} from "../Entities/Event";
import {DeleteEvent} from "../Services/EventServices/DeleteEvent";
import {Celebration} from "@mui/icons-material";
import AddEventDialog from "../Components/AddEventDialog";


const Events = (props: { user: IUser, events: IEvent[], setEvents: (e: IEvent[]) => void }) => {
    const {user, events, setEvents } = props;

    const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
    const [isOpenEditDialog, setIsOpenEditDialog] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [selectedEvent, setSelectedEvent] = React.useState<IEvent | undefined>(undefined);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storage.length) : 0;

    React.useEffect(() => {
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setIsOpenEditDialog(false);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = (event: IEvent) => {
        DeleteEvent(event).then(() => {
            const index = events.indexOf(event);
            events.splice(index, 1);
            setEvents([...events]);
        });
    }

    const getStatus = (event: IEvent) => {
        const now = new Date();

        if (new Date(event.startDateTime) <= now && new Date(event.endDateTime) >= now) {
            return <Typography sx={{ color: '#768c1e' }}>Laufend</Typography>
        } else if (new Date(event.endDateTime) < now) {
            return <Typography sx={{ color: '#7c2121' }}>Beendet</Typography>;
        } else {
            return <Typography sx={{ color: '#d2a12a' }}>Bevorstehend</Typography>;
        }
    }

    return (
        <Box sx={{
            height: '80vh',
            maxHeight: '80vh',
            width: "100%",
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '25%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            { isOpenDialog && <AddEventDialog open={isOpenDialog} setOpen={setIsOpenDialog} onClose={handleCloseDialog} events={events} setEvents={setEvents} /> }
            <TableContainer component={Paper} sx={{
                height: '75%',
                maxHeight: '75%',
            }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{
                            backgroundColor: 'rgba(192,173,109,0.35)'
                        }}>
                            <TableCell align="left" sx={{
                                fontWeight: 'bold',
                            }}>Event</TableCell>
                            <TableCell align="left" sx={{
                                fontWeight: 'bold',
                            }}>Beschreibung</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Location</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Started am</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Endet am</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Status</TableCell>
                            <TableCell align="center" sx={{
                                fontWeight: 'bold',
                            }}>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : events
                        ).map((event: IEvent) => (
                            <TableRow key={event.id}>
                                <TableCell align="left">
                                    {event.name}
                                </TableCell>
                                <TableCell align="left">
                                    {event.description}
                                </TableCell>
                                <TableCell align="center">{event.location}</TableCell>
                                <TableCell align="center">{String(event.startDateTime).split('T')[0]}</TableCell>
                                <TableCell align="center">{String(event.endDateTime).split('T')[0]}</TableCell>
                                <TableCell align="center">
                                    {getStatus(event)}
                                </TableCell>
                                <TableCell align="center">
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#1b861b'
                                        }}>
                                            <EditNoteIcon onClick={() => setSelectedEvent(event)} sx={{
                                                color: 'white',
                                            }}/>
                                        </Button>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#861b1b'
                                        }}>
                                            <DeleteIcon onClick={() => handleDelete(event)} sx={{
                                                color: '#ffffff',
                                            }}/>
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}
            />
            { user.role.includes('A') &&
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 50,
                        right: 50,
                        zIndex: 99
                    }}
                >
                    <Fab aria-label="Neues Getränk hinzufügen" onClick={() => setIsOpenDialog(true)}
                         sx={{
                             backgroundColor: 'rgb(192,173,109)',
                             boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                             width: '100px',
                             height: '100px',
                         }}>
                        <AddIcon/>
                        <Celebration/>
                    </Fab>
                </Box>
            }
        </Box>
    );
}

export default Events;