// @ts-nocheck
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    overrides: {
        MuiSwitch: {
            main: '#ffffff',
            switchBase: {
                '&$checked': {
                    color: '#ffffff', // Weiß
                },
                '&$checked + $track': {
                    backgroundColor: '#ffffff', // Weiß
                },
            },
            checked: {},
            track: {},
        },
        MuiIconButton: {
            root: {
                color: '#849b3d',
            },
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#648041',
        },
        secondary: {
            main: '#5393c4', // Pink A400
        },
        warning: {
            main: '#839346FF',
        },
        error: {
            main: '#b94239', // Red
        },
        background: {
            default: '#fafafa', // Light Grey
            paper: '#ffffff', // White
        },
        text: {
            primary: '#000000', // Black
            secondary: '#3f51b5', // Indigo
        },
    },
});

export default lightTheme;