import axios from "axios";
import {IBill} from "../../Entities/Bill";


export const SaveBills = async (bills: IBill[]) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/bills/bulk`, bills, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}