import React from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, IconButton, Slider,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {SaveDrinkStorage} from "../Services/DrinkStorageServices/SaveDrinkStorage";
import {IDrink} from "../Entities/Drink";
import {IDrinkStorage} from "../Entities/DrinkStorage";
import CloseIcon from "@mui/icons-material/Close";


const AddDrinkStorageDialog = (props: {
    open: boolean,
    setOpen: (b: boolean) => void,
    onClose: () => void,
    drinks: IDrink[]
}) => {
    const {open, setOpen, onClose, drinks} = props;

    const [drink, setDrink] = React.useState<IDrink | undefined>(undefined);
    const [boxName, setBoxName] = React.useState<string>('');
    const [price, setPrice] = React.useState<number>(0);
    const [litersPerBottle, setLitersPerBottle] = React.useState<number>(0);
    const [bottlesPerCrate, setBottlesPerCrate] = React.useState<number>(0);
    const [inBoxes, setInBoxes] = React.useState<boolean>(true);
    const [crates, setCrates] = React.useState<number>(0);
    const [currentBoxQuantity, setCurrentBoxQuantity] = React.useState<number>(0);
    const [currentLiterQuantity, setCurrentLiterQuantity] = React.useState<number>(0);
    const [warningQuantity, setWarningQuantity] = React.useState<number>(0);

    const handleAdd = () => {
        const drinkStorage: IDrinkStorage = {
            name: boxName,
            price: price,
            literPerBottle: litersPerBottle,
            inBoxes: inBoxes,
            boxQuantity: crates,
            quantityPerBox: bottlesPerCrate,
            drink: {id: drink?.id} as IDrink,
            currentBoxQuantity: bottlesPerCrate * crates,
            currentLiterQuantity: litersPerBottle * bottlesPerCrate * crates
        }
        SaveDrinkStorage(drinkStorage).then(() => {
            setOpen(false);
            onClose();
        });
    };

    const getDrinkFromName = (name: string) => {
        return drinks.find(drink => drink.name === name);
    }

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                Getränk hinzufügen
                <IconButton onClick={onClose} sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    marginBottom: 1,
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Name der Kiste"
                    value={boxName}
                    onChange={(event) => setBoxName(event.target.value)}
                    fullWidth
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                />
                <Autocomplete
                    options={drinks.map((drink) => drink.name)}
                    value={drink ? drink.name : ''}
                    onInputChange={(event, newValue) => {
                        setDrink(getDrinkFromName(newValue));
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label="Getränkename"/>}
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Preis"
                    value={price}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setPrice(0);
                            return;
                        }
                        setPrice(parseFloat(event.target.value));
                    }}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Liter pro Flasche"
                    value={litersPerBottle}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setLitersPerBottle(0);
                            return;
                        }
                        setLitersPerBottle(parseFloat(event.target.value));
                    }}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <Typography>
                    In Kisten?
                </Typography>
                <Switch
                    checked={inBoxes}
                    onChange={(event) => setInBoxes(event.target.checked)}
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Flaschen pro Kiste"
                    value={bottlesPerCrate}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setBottlesPerCrate(0);
                            return;
                        }
                        setBottlesPerCrate(parseFloat(event.target.value))
                    }}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Anzahl der Kisten"
                    value={crates}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setCrates(0);
                            return;
                        }
                        setCrates(parseFloat(event.target.value));
                    }}
                    fullWidth
                />
                <Divider sx={{marginTop: 3, marginBottom: 3}}/>
                <Typography variant="h6">
                    Einstellungen für das Lager
                </Typography>
                <Typography sx={{
                    color: '#debd00',
                }}>
                    Warnung bei weniger als {warningQuantity} Flaschen
                </Typography>
                <Slider sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
                        value={warningQuantity}
                        onChange={(event, newValue) => setWarningQuantity(newValue as number)}
                        valueLabelDisplay="auto"
                        step={1}
                        min={0}
                        max={bottlesPerCrate * crates}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} fullWidth sx={{
                    backgroundColor: '#934343',
                    color: '#ffffff',
                }}>
                    Abbrechen
                </Button>
                <Button onClick={handleAdd} fullWidth sx={{
                    backgroundColor: '#1b861b',
                    color: '#ffffff',
                }}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddDrinkStorageDialog;