import axios from "axios";
import {IOpeningHour} from "../../Entities/OpeningHour";



export const AddOpeningHour = async (openingHour: IOpeningHour) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/business_hours/regular`, openingHour, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}