import {IUser} from "../../Entities/User";
import axios, {AxiosResponse} from "axios";


export const UploadUserImage = async (id: number, image: File) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    const formData = new FormData();
    formData.append("image", image);
    return await axios.put(`${backendUrl}/users/${id}/uploadImage`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}