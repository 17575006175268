// @ts-nocheck


import axios, {AxiosResponse} from "axios";
import {IDrinkStorage} from "../../Entities/DrinkStorage";

export const SaveDrinkStorage = async (drinkStorage: IDrinkStorage): Promise<AxiosResponse<IDrinkStorage>> => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/drinkStorages`, drinkStorage, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return {
                id: response.data["id"],
                name: response.data["name"],
                price: response.data["price"],
                literPerBottle: response.data["literPerBottle"],
                inBoxes: response.data["inBoxes"],
                boxQuantity: response.data["boxQuantity"],
                quantityPerBox: response.data["quantityPerBox"],
                drink: response.data["drink"],
                currentBoxQuantity: response.data["currentBoxQuantity"],
                currentLiterQuantity: response.data["currentLiterQuantity"],
                warningQuantity: response.data["warningQuantity"]
            };
        }
    );
}