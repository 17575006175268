// @ts-nocheck
import {IUser} from "../Entities/User";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle, Divider,
    FormControl,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {UpdateUser} from "../Services/UserServices/UpdateUser";
import Avatar from '@mui/material/Avatar';
import {UploadDrinkImage} from "../Services/DrinksServices/UploadDrinkImage";
import {UploadUserImage} from "../Services/UserServices/UploadUserImage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ChangePassword} from "../Services/UserServices/ChangePassword";


const EditUserProfils = (props: {
    user: IUser,
    setUser: (u: IUser) => void,
    isOpen: boolean,
    setIsOpen: (b: boolean) => void,
    logout: () => void
}) => {

    const {user, setUser, isOpen, setIsOpen, logout} = props;

    const [changedUser, setChangedUser] = React.useState<boolean>(false);

    const [username, setUsername] = React.useState<string>(user?.username || '');
    const [email, setEmail] = React.useState<string>(user?.email || '');

    const [oldPassword, setOldPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [newPasswordRepeat, setNewPasswordRepeat] = React.useState<string>('');

    React.useEffect(() => {
        if (username !== user.username || email !== user.email || oldPassword !== '' || newPassword !== '' || newPasswordRepeat !== '') {
            setChangedUser(true);
        } else {
            setChangedUser(false);
        }
    }, [username, email, oldPassword, newPassword, newPasswordRepeat]);

    const handleSaveChanges = () => {
        if (changedUser) {
            UpdateUser({
                id: user.id,
                username: username,
                email: email,
                password: '',
                role: user.role,
                image: user.image,
                employeeId: user.employeeId,
                confirmedMail: user.confirmedMail
            }).then(() => {
                console.log('User updated');
                if (oldPassword !== '' && newPassword !== '' && newPasswordRepeat !== '' && newPassword === newPasswordRepeat) {
                    ChangePassword(
                        email,
                        oldPassword,
                        user.employeeId,
                        newPassword,
                        newPasswordRepeat
                    ).then((res) => {
                        console.log(res)
                        if (res.message !== '200') {
                            console.error('Password change failed');
                        } else {
                            setOldPassword('');
                            setNewPassword('');
                            setNewPasswordRepeat('');
                            setIsOpen(false);
                            logout();
                        }
                    });
                }
                setUser({
                    id: user.id,
                    username: username,
                    email: email,
                    password: '',
                    role: user.role,
                    image: user.image,
                    employeeId: user.employeeId
                });
                localStorage.setItem('user', JSON.stringify({
                    id: user.id,
                    username: username,
                    email: email,
                    password: '',
                    role: user.role,
                    image: user.image,
                    employeeId: user.employeeId
                }));
            });
        }
    }

    const handleSelectImage = (id: number) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.jpg,.jpeg,.png';
        input.onchange = (event) => {
            const file = (event.target as HTMLInputElement).files[0];
            let result: string | null;
            if (file) {
                UploadUserImage(id, file).then((res) => {
                    result = res;
                }).catch(() => {
                    console.error('Image upload failed');
                }).finally(() => {
                    if (result) {
                        user.image = result;
                        setUser(user);
                    }
                });
            }
        };
        input.click();
    }

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <DialogTitle sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                Profil bearbeiten
                <IconButton onClick={() => setIsOpen(false)}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <Avatar
                        alt={user !== null ? user.username : "Gast"}
                        src={user !== null && user.image !== undefined ? `data:image/jpeg;base64,${user?.image}` : ""}
                        onClick={(e) => handleSelectImage(user.id)}
                        sx={{width: 200, height: 200, margin: 'auto', '&:hover': {cursor: 'pointer'}}}
                    />
                    <TextField sx={{marginTop: 2, marginBottom: 2}}
                               label="Benutzername"
                               type="text"
                               value={username}
                               onChange={(event) => {
                                   setUsername(event.target.value);
                               }}
                               fullWidth
                    />
                    <TextField sx={{marginTop: 2, marginBottom: 2}}
                               label="E-Mail"
                               type="text"
                               value={email}
                               onChange={(event) => {
                                   setEmail(event.target.value);
                               }}
                               fullWidth
                    />
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>

                            <Typography sx={{
                                fontWeight: 'bold'
                            }}>
                                Passwort Optionen
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Aktuelles Passwort
                            </Typography>
                            <TextField label="Passwort" type="password"
                                       value={user?.password}
                                       onChange={(event) => {
                                           setOldPassword(event.target.value);
                                       }}
                                       sx={{marginBottom: 2}}
                                       fullWidth
                                       disabled
                            />
                            <Divider/>
                            <Typography sx={{marginTop: 2}}>
                                Neues Passwort
                            </Typography>
                            <TextField label="Passwort" type="password"
                                       value={user?.password}
                                       onChange={(event) => {
                                           setNewPassword(event.target.value);
                                       }}
                                       fullWidth
                                       disabled
                            />
                            <Typography>
                                Passwort wiederholen
                            </Typography>
                            <TextField label="Passwort" type="password"
                                       value={user?.password}
                                       onChange={(event) => {
                                           setNewPasswordRepeat(event.target.value);
                                       }}
                                       fullWidth
                                       disabled
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Button type="submit"
                            onClick={() => {
                                handleSaveChanges();
                            }}
                            fullWidth
                            sx={{
                                backgroundColor: 'rgba(192,173,109,0.85)',
                                color: 'black',
                                marginTop: 2,
                                "&:hover": {
                                    backgroundColor: 'rgba(192,173,109,0.65)',
                                }
                            }}>
                        Änderungen Speichern
                    </Button>
                </FormControl>
            </DialogContent>
        </Dialog>
    )
}

export default EditUserProfils;