// @ts-nocheck

import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {IUser} from "../Entities/User";
import {Card, CardActionArea, CardContent, Typography, Box} from '@mui/material';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import EngineeringIcon from '@mui/icons-material/Engineering';

const HomeGuest = (props: { user: IUser, setSelectedPage: (p: string) => void }) => {

    const {user, setSelectedPage} = props;
    const navigate = useNavigate();

    const navigationCards = [
        {title: 'Getränke', icon: <LocalBarIcon/>, path: '/drinks'},
        {title: 'Kunden', icon: <FaceRetouchingNaturalIcon/>, path: '/customers'},
    ];

    React.useEffect(() => {
        if (user === null) {
            navigate('/login');
        }
    }, []);

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Typography variant="h3" sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px'
            }}>
                Willkommen {user !== null ? user.username : "Gast"}
            </Typography>
            <Typography variant="h6" sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px'
            }}>
                Du kannst diese Navigation, oder die Topbar verwenden.
            </Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                {navigationCards.map((card, index) => (
                    <Card key={index} sx={{maxWidth: 500, m: 2}} onClick={() => setSelectedPage(card.title)}>
                        <CardActionArea onClick={() => navigate(card.path)}>
                            <CardContent>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    {card.icon}
                                    <Typography variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    Hier können Sie {card.title} verwalten.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
            <Typography variant="h5" sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px'
            }}>
                Im folgenden werden Meldungen zum Server angezeigt, falls es welche gibt, wie z.B. Wartungsarbeiten.
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
            }}>
                <Card key={999} sx={{
                    width: "80%",
                    m: 2,
                    boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.75)',
                    backgroundColor: '#e5d699'
                }}>
                    <CardActionArea>
                        <CardContent>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <EngineeringIcon/>
                                <Typography variant="h5" component="div">
                                    Wartungsarbeiten
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                Der Server wird regelmäßig jede Woche Montag von 14:00 bis 16:00 Uhr gewartet.
                                Speichere deine Daten rechtzeitig ab.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Box>
    );
}

export default HomeGuest;