import axios from "axios";
import {IEvent} from "../../Entities/Event";


export const AddEvent = async (event: IEvent) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.post(`${backendUrl}/events`, event, {
        headers: {
            'authorization': `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        async (response: any) => {
            return response.data;
        }
    ).catch((error: any) => {
        console.error(error);
        return null;
    });
}