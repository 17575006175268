import {IDrinkLiterPerOrders} from "../../Entities/DrinkLiterPerOrders";
import {IDrinkExtraOption} from "../../Entities/DrinkExtraOption";
import axios from "axios";
import {IDrink} from "../../Entities/Drink";


export const GetDrinkById = async (id: number, extraOptions: boolean = false) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/drinks/${id}`, {
        headers: {
            'authorization': `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            let drinkExtraOptions: IDrinkExtraOption[] = [];
            if (extraOptions) {
                drinkExtraOptions = response.data["drinkExtraOptions"].map((option: any) => {
                    const drinkExtraOption: IDrinkExtraOption = {
                        id: option["id"],
                        name: option["name"],
                        price: option["price"],
                        defaultOption: option["defaultOption"],
                    }
                    return drinkExtraOption;
                });
            }

            const drink: IDrink = {
                id: response.data["id"],
                name: response.data["name"],
                category: response.data["category"],
                description: response.data["description"],
                refPrice: response.data["refPrice"],
                price: response.data["price"],
                image: response.data["image"],
                drinkExtraOptions: !extraOptions ? [] : drinkExtraOptions,
                literPerOrder: response.data["literPerOrder"],
                drinkLiterPerOrders: [],
            }
            return drink;
        }
    );
}