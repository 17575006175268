import axios from "axios";
import {IExceptionDay} from "../../Entities/ExceptionDay";



export const DeleteExceptionDay = async (exceptionDay: IExceptionDay) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.delete(`${backendUrl}/exception_days/${exceptionDay.id}`, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return response.data;
        }
    );
}