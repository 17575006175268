// @ts-nocheck
import React from "react";
import Avatar from "@mui/material/Avatar";
import {IUser} from "../Entities/User";
import EditUserProfil from "../Pages/EditUserProfil";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StorageIcon from '@mui/icons-material/Storage';


const UserButton = (props: { user: IUser, setUser: (u: IUser) => void }) => {

    const {user, setUser} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openProfile, setOpenProfile] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [])

    const logout = () => {
        navigate('/login');
        localStorage.clear();
        window.location.reload();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Avatar
                alt={user !== null ? user.username : "Gast"}
                src={user !== null && user.image !== undefined ? `data:image/jpeg;base64,${user?.image}` : ""}
                onClick={handleClick}
                sx={{
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                    width: '100px',
                    height: '100px',
                    position: 'fixed',
                    top: 20,
                    right: 50,
                    zIndex: 200,
                    '&:hover': {
                        cursor: 'pointer',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease-in-out',
                    }
                }}
            />
            {
                Boolean(anchorEl) ?
                    <>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            sx={{
                                width: '200px',
                            }}
                        >
                            <MenuItem onClick={() => {
                                setOpenProfile(true);
                                setAnchorEl(null);
                            }}>
                                <ManageAccountsIcon/>
                                Profil bearbeiten
                            </MenuItem>
                            {
                                user.role.includes('T') ?
                                    <MenuItem onClick={() => {
                                        navigate('/customers');
                                        setAnchorEl(null);
                                    }}>
                                        <StorageIcon/>
                                        Server verwalten
                                    </MenuItem>
                                    : null
                            }
                            <MenuItem onClick={logout}
                                      sx={{
                                          '&:hover': {
                                              backgroundColor: 'red',
                                              color: 'white'
                                          }
                                      }}>
                                <LogoutIcon/>
                                Logout
                            </MenuItem>
                        </Menu>
                    </>
                    : null
            }
            {openProfile && <EditUserProfil user={user} setUser={setUser} isOpen={openProfile} setIsOpen={setOpenProfile} logout={logout}/>}
        </>
    )
}

export default UserButton;