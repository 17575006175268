import axios, {AxiosResponse} from "axios";


export const ChangePassword = async (
    email: string,
    oldPassword: string,
    employeeId: string,
    newPassword: string,
    repeatedNewPassword: string) =>
{

    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    if (newPassword !== repeatedNewPassword) {
        return {message: 'Die Passwörter stimmen nicht überein.'};
    }

    return await axios.post(`${backendUrl}/users/verify`, {
        email: email,
        password: oldPassword,
        employeeId: employeeId
    }, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: AxiosResponse) => {
            if (response.status === 200) {
                return axios.post(`${backendUrl}/users/changePassword`, {
                    email: email,
                    password: newPassword,
                    employeeId: employeeId
                }, {
                    headers: {
                        Authorization: `${localStorage.getItem('jwtToken')}`
                    }
                }).then(
                    (response: AxiosResponse) => {
                        if (response.status === 200) {
                            return {message: String(response.status) };
                        }
                    }
                );
            } else {
                return {message: 'Falsche Benutzerinformationen.'};
            }
        }
    );
}
