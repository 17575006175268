// @ts-nocheck
import React from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, IconButton, Slider,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {SaveDrinkStorage} from "../Services/DrinkStorageServices/SaveDrinkStorage";
import {IDrink} from "../Entities/Drink";
import {IDrinkStorage} from "../Entities/DrinkStorage";
import CloseIcon from "@mui/icons-material/Close";
import {UpdateDrinkStorage} from "../Services/DrinkStorageServices/UpdateDrinkStorage";


const EditDrinkStorageDialog = (props: {
    open: boolean,
    setOpen: (b: boolean) => void,
    onClose: () => void,
    drinks: IDrink[],
    drinkStorage: IDrinkStorage,
    setDrinkStorage: (ds: IDrinkStorage) => void
}) => {
    const {open, setOpen, onClose, drinks, drinkStorage, setDrinkStorage} = props;

    const [drink, setDrink] = React.useState<IDrink | undefined>(undefined);
    const [boxName, setBoxName] = React.useState<string>('');
    const [price, setPrice] = React.useState<number>(0);
    const [litersPerBottle, setLitersPerBottle] = React.useState<number>(0);
    const [bottlesPerCrate, setBottlesPerCrate] = React.useState<number>(0);
    const [inBoxes, setInBoxes] = React.useState<boolean>(true);
    const [crates, setCrates] = React.useState<number>(0);
    const [currentBoxQuantity, setCurrentBoxQuantity] = React.useState<number>(0);
    const [currentLiterQuantity, setCurrentLiterQuantity] = React.useState<number>(0);
    const [warningQuantity, setWarningQuantity] = React.useState<number>(0);
    const [tmpDrinkStorage, setTmpDrinkStorage] = React.useState<IDrinkStorage>(drinkStorage);

    const handleUpdateStorage = (ds: IDrinkStorage) => {
        UpdateDrinkStorage(ds).then(() => {
            setDrinkStorage(ds);
        });
    }

    const getDrinkFromName = (name: string) => {
        return drinks.find(drink => drink.name === name);
    }

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                Getränk bearbeiten
                <IconButton onClick={onClose} sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    marginBottom: 1,
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Name der Kiste"
                    value={tmpDrinkStorage.name}
                    onChange={(event) => {
                        setTmpDrinkStorage({
                            ...tmpDrinkStorage,
                            name: event.target.value
                        })
                    }}
                    fullWidth
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                />
                <Autocomplete
                    options={drinks.map((drink) => drink.name)}
                    value={tmpDrinkStorage.drink.name}
                    renderInput={(params) => <TextField {...params}
                                                        label="Getränkename"/>}
                    sx={{
                        marginBottom: 1,
                    }}
                    disabled
                />
                <TextField
                    label="Preis"
                    value={tmpDrinkStorage.price}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setPrice(0);
                            return;
                        }
                        setTmpDrinkStorage({
                            ...tmpDrinkStorage,
                            price: parseFloat(event.target.value)
                        })
                    }}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Liter pro Flasche"
                    value={tmpDrinkStorage.literPerBottle}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setLitersPerBottle(0);
                            return;
                        }
                        setTmpDrinkStorage({
                            ...tmpDrinkStorage,
                            literPerBottle: parseFloat(event.target.value)
                        })
                    }}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <Typography>
                    In Kisten?
                </Typography>
                <Switch
                    checked={tmpDrinkStorage.inBoxes}
                    onChange={(event) => {
                        setTmpDrinkStorage({
                            ...tmpDrinkStorage,
                            inBoxes: event.target.checked
                        });
                    }}
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Flaschen pro Kiste"
                    value={tmpDrinkStorage.boxQuantity}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setBottlesPerCrate(0);
                            return;
                        }
                        setTmpDrinkStorage({
                            ...tmpDrinkStorage,
                            boxQuantity: parseFloat(event.target.value)
                        });
                    }}
                    fullWidth
                    sx={{
                        marginBottom: 1,
                    }}
                />
                <TextField
                    label="Anzahl der Kisten"
                    value={tmpDrinkStorage.quantityPerBox}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setCrates(0);
                            return;
                        }
                        setTmpDrinkStorage({
                            ...tmpDrinkStorage,
                            quantityPerBox: parseFloat(event.target.value)
                        });
                    }}
                    fullWidth
                />
                <Divider sx={{marginTop: 3, marginBottom: 3}}/>
                <Typography variant="h6">
                    Einstellungen für das Lager
                </Typography>
                <Typography sx={{
                    color: '#debd00',
                }}>
                    Warnung bei weniger als {tmpDrinkStorage.warningQuantity} Flaschen
                </Typography>
                <Slider sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
                        value={tmpDrinkStorage.warningQuantity}
                        onChange={(event, newValue) => {
                            setTmpDrinkStorage({
                                ...tmpDrinkStorage,
                                warningQuantity: newValue as number
                            });
                        }}
                        valueLabelDisplay="auto"
                        step={1}
                        min={0}
                        max={drinkStorage.quantityPerBox * drinkStorage.boxQuantity}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} fullWidth sx={{
                    backgroundColor: '#934343',
                    color: '#ffffff',
                }}>
                    Abbrechen
                </Button>
                <Button onClick={() => handleUpdateStorage(tmpDrinkStorage)} fullWidth sx={{
                    backgroundColor: '#1b861b',
                    color: '#ffffff',
                }}>
                    Updaten
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditDrinkStorageDialog