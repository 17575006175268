// @ts-nocheck


import axios, {AxiosResponse} from "axios";
import {IDrinkStorage} from "../../Entities/DrinkStorage";

export const UpdateDrinkStorage = async (drinkStorage: IDrinkStorage): Promise<AxiosResponse<IDrinkStorage>> => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.put(`${backendUrl}/drinkStorages`, drinkStorage, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        (response: any) => {
            return {
                id: response.data["id"],
                name: response.data["name"],
                price: response.data["price"],
                drink: {
                    id: response.data["drink"]["id"],
                    name: response.data["drink"]["name"],
                    price: response.data["drink"]["price"],
                    refPrice: response.data["drink"]["refPrice"],
                    description: response.data["drink"]["description"],
                    image: response.data["drink"]["image"],
                    drinkExtraOptions: {
                        id: response.data["drink"]["drinkExtraOptions"]["id"],
                        name: response.data["drink"]["drinkExtraOptions"]["name"],
                        price: response.data["drink"]["drinkExtraOptions"]["price"]
                    },
                    category: response.data["drink"]["category"]
                },
                literPerBottle: response.data["literPerBottle"],
                inBoxes: response.data["inBoxes"],
                boxQuantity: response.data["boxQuantity"],
                quantityPerBox: response.data["quantityPerBox"],
                currentBoxQuantity: response.data["currentBoxQuantity"],
                currentLiterQuantity: response.data["currentLiterQuantity"],
                warningQuantity: response.data["warningQuantity"]
            };
        }
    );
}