import {IDrinkExtraOption} from "../../Entities/DrinkExtraOption";
import {IDrink} from "../../Entities/Drink";
import {IDrinkLiterPerOrders} from "../../Entities/DrinkLiterPerOrders";
import {GetDrinkById} from "./GetDrinkById";
import axios from "axios";


export const GetAllDrinks = async (getImage: boolean = true) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/drinks`, {
        headers: {
            'authorization': `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        async (response: any) => {
            const drinks: IDrink[] = [];
            for (const drink of response.data) {
                let drinkExtraOptions: IDrinkExtraOption[] = [];
                if (drink["drinkExtraOptions"] !== undefined) {
                    drinkExtraOptions = drink["drinkExtraOptions"].map((extra: any) => {
                        return {
                            id: extra["id"],
                            name: extra["name"],
                            price: extra["price"],
                            defaultOption: extra["defaultOption"],
                        };
                    });
                }

                let drinkLiterPerOrder: IDrinkLiterPerOrders[] = [];
                if (drink["drinkExtraOptions"] !== undefined) {
                    drinkLiterPerOrder = await Promise.all(drink["drinkLiterPerOrders"].map(async (order: any) => {
                        let additionalDrink;
                        let drink;
                        if (typeof order["additionalDrink"] === "number") {
                            additionalDrink = await GetDrinkById(order["additionalDrink"]);
                        } else {
                            additionalDrink = {
                                id: order["additionalDrink"]["id"],
                                name: order["additionalDrink"]["name"],
                                category: order["additionalDrink"]["category"],
                                description: order["additionalDrink"]["description"],
                                refPrice: order["additionalDrink"]["refPrice"],
                                price: order["additionalDrink"]["price"],
                                image: order["additionalDrink"]["image"],
                                drinkExtraOptions: order["additionalDrink"]["drinkExtraOptions"],
                                literPerOrder: order["additionalDrink"]["literPerOrder"],
                                drinkLiterPerOrders: order["additionalDrink"]["drinkLiterPerOrders"],
                            };
                        }

                        if (typeof order["drink"] === "number") {
                            drink = await GetDrinkById(order["drink"]);
                        } else {
                            drink = {
                                id: order["drink"]["id"],
                                name: order["drink"]["name"],
                                category: order["drink"]["category"],
                                description: order["drink"]["description"],
                                refPrice: order["drink"]["refPrice"],
                                price: order["drink"]["price"],
                                image: order["drink"]["image"],
                                drinkExtraOptions: order["drink"]["drinkExtraOptions"],
                                literPerOrder: order["drink"]["literPerOrder"],
                                drinkLiterPerOrders: order["drink"]["drinkLiterPerOrders"],
                            };
                        }


                        const literPerOrder: IDrinkLiterPerOrders = {
                            id: order["id"],
                            drink: drink,
                            additionalDrink: additionalDrink,
                            literPerOrder: order["literPerOrder"],
                        };
                        return literPerOrder;
                    }));
                }

                // Fetch the image separately
                let imageUrl: string | null = null;
                if (getImage) {
                    try {
                        const imageResponse = await axios.get(`${backendUrl}/drinks/${drink.id}/image`, {
                            headers: {
                                'authorization': `${localStorage.getItem('jwtToken')}`
                            },
                            responseType: 'arraybuffer'
                        });
                        const base64 = btoa(new Uint8Array(imageResponse.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                        imageUrl = `data:image/jpeg;base64,${base64}`;
                    } catch (error) {
                        console.error(`Error fetching image for drink ID ${drink.id}:`, error);
                    }
                }

                if (typeof drink === "number") {
                    const d: IDrink = await GetDrinkById(drink);
                    drinks.push(d);
                } else {
                    drinks.push({
                        id: drink["id"],
                        name: drink["name"],
                        category: drink["category"],
                        description: drink["description"],
                        refPrice: drink["refPrice"],
                        price: drink["price"],
                        image: imageUrl,
                        drinkExtraOptions: drinkExtraOptions,
                        literPerOrder: drink["literPerOrder"],
                        drinkLiterPerOrders: drinkLiterPerOrder,
                    });
                }
            }
            return drinks;
        }
    );
}