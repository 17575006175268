// @ts-nocheck
import {IOrder} from "../../Entities/Order";
import axios from "axios";
import {ICustomer} from "../../Entities/Customer";
import {IDrink} from "../../Entities/Drink";
import {GetDrinkById} from "../DrinksServices/GetDrinkById";


export const GetAllOrders = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/api';
    return await axios.get(`${backendUrl}/orders`, {
        headers: {
            Authorization: `${localStorage.getItem('jwtToken')}`
        }
    }).then(
        async (response: any) => {
            const ordersPromises = response.data.map(async (order: any) => {
                // Check if order is a number, if yes, return null
                if (typeof order === "number") {
                    return null;
                }
                let drink: IDrink | null = null;
                if (typeof order["drink"] === "number") {
                    drink = await GetDrinkById(order["drink"], true);
                } else {
                    drink = {
                        id:  order["drink"]["id"],
                        name:  order["drink"]["name"],
                        description:  order["drink"]["description"],
                        category:  order["drink"]["category"],
                        price:  order["drink"]["price"],
                        refPrice:  order["drink"]["refPrice"],
                        image:  order["drink"]["image"],
                        drinkExtraOptions: order["drink"]["drinkExtraOptions"],
                    };
                }
                const customer: ICustomer = {
                    id: order["customer"]["id"],
                    name: order["customer"]["name"]
                }
                return {
                    id: order["id"],
                    status: order["status"],
                    customer: customer,
                    drink: drink,
                    sum: order["sum"],
                    quantity: order["quantity"],
                    dateTime: order["dateTime"],
                    drinkOptionId: order["drinkOptionId"],
                    userId: order["userId"],
                };
            });

            const orders = await Promise.all(ordersPromises);
            return orders.filter(order => order !== null);
        }
    );
}