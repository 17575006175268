// @ts-nocheck

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {ICustomer} from "../Entities/Customer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {IOrder} from "../Entities/Order";
import React from "react";
import {SaveCustomer} from "../Services/CustomerServices/SaveCustomer";
import {SaveOrder} from "../Services/OrderServices/SaveOrder";
import {UpdateCustomer} from "../Services/CustomerServices/UpdateCustomer";
import {IDrink} from "../Entities/Drink";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {DeleteOrder} from "../Services/OrderServices/DeleteOrder";
import {DeleteCustomer} from "../Services/CustomerServices/DeleteCustomer";
import Fuse from "fuse.js";
import Fab from "@mui/material/Fab";
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import {IUser} from "../Entities/User";
import {IDrinkExtraOption} from "../Entities/DrinkExtraOption";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import {UpdateOrder} from "../Services/OrderServices/UpdateOrder";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {GetAllOrders} from "../Services/OrderServices/GetAllOrders";


const Customers = (props: {
    user: IUser,
    customers: ICustomer[],
    setCustomers: (customers: ICustomer[]) => void,
    orders: IOrder[],
    setOrders: (orders: IOrder[]) => void,
    drinks: IDrink[]
}) => {

    const {user, customers, setCustomers, orders, setOrders, drinks} = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [loading, setLoading] = React.useState(false);
    const [addingOrder, setAddingOrder] = React.useState(false);
    const [newOrderDrink, setNewOrderDrink] = React.useState<IDrink | null>(null);
    const [newOrderDrinkName, setNewOrderDrinkName] = React.useState('');
    const [newOrderQuantity, setNewOrderQuantity] = React.useState(1);
    const [newOrderPaid, setNewOrderPaid] = React.useState(false);
    const [newOrderSum, setNewOrderSum] = React.useState(0);
    const [isSumEnabled, setIsSumEnabled] = React.useState(false);
    const [searchCustomer, setSearchCustomer] = React.useState('');
    const [currentDayOrders, setCurrentDayOrders] = React.useState<IOrder[]>([]);
    const [selectedDay, setSelectedDay] = React.useState(new Date().toISOString().split('T')[0]);
    const [uniqueDays, setUniqueDays] = React.useState<string[]>([]);
    const [newCustomerName, setNewCustomerName] = React.useState('');
    const [addingNewCustomer, setAddingNewCustomer] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(0);
    const [expandedCustomer, setExpandedCustomer] = React.useState<number | null>(null);
    const [showAllPaidCustomers, setShowAllPaidCustomers] = React.useState(false);
    const [unpaidCustomers, setUnpaidCustomers] = React.useState<ICustomer[]>([]);
    const [newCustomerNameWarning, setNewCustomerNameWarning] = React.useState(false);

    const [fabPosition, setFabPosition] = React.useState({x: 50, y: 50});
    const [prevTouchPosition, setPrevTouchPosition] = React.useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = React.useState(false);

    const bottomRef = React.useRef(null);
    const endOfTableRef = React.useRef(null);

    const [selectedCustomer, setSelectedCustomer] = React.useState<ICustomer | null>(null);

    let emptyRows;
    if (showAllPaidCustomers) {
        emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;
    } else {
        emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - unpaidCustomers.length) : 0;
    }

    const options = {
        includeScore: true,
        keys: ['name']
    };

    let result;
    let fuse = new Fuse(customers, options);
    if (!showAllPaidCustomers) {
        fuse = new Fuse(unpaidCustomers, options);
    }
    if (searchCustomer.trim() === '') {
        result = showAllPaidCustomers ? customers : unpaidCustomers;
    } else {
        result = fuse.search(searchCustomer).map(({item}) => item);
    }

    React.useEffect(() => {
        const ordersToday = orders.filter(order => order.dateTime.toString().split('T')[0] === selectedDay);
        setCurrentDayOrders(ordersToday);

        setUniqueDays([...new Set(orders.map(order => order.dateTime.toString().split('T')[0]))]);
    }, []);

    React.useEffect(() => {
        handleShowCustomersWithStatus();
    }, [customers]);

    React.useEffect(() => {
        handleShowCustomersWithStatus();
    }, [unpaidCustomers]);

    React.useEffect(() => {
        handleShowCustomersWithStatus();
    }, [showAllPaidCustomers]);

    React.useEffect(() => {
        if (selectedDay === 'Alle') {
            // Filter the orders based on the selected day and the status if all orders are paid is checked
            let tmpOrders: IOrder[] = showAllPaidCustomers ? orders : orders.filter(order => !order.status);
            setCurrentDayOrders(tmpOrders);
            handleShowCustomersWithStatus();
        } else {
            let tmpOrders: IOrder[] = showAllPaidCustomers ? orders : orders.filter(order => !order.status);
            tmpOrders = tmpOrders.filter(order => order.dateTime.toString().split('T')[0] === selectedDay);
            setCurrentDayOrders(tmpOrders);
            handleShowCustomersWithStatus();
        }
    }, [selectedDay]);

    React.useEffect(() => {
        const drink = getDrinkFromName(newOrderDrinkName);
        if (drink) {
            const defaultOptionId = drink.drinkExtraOptions.find(option => option.defaultOption)?.id || 0;
            setSelectedOption(defaultOptionId);

            if (drink?.drinkExtraOptions.length > 0) {
                const defaultOption: IDrinkExtraOption = drink?.drinkExtraOptions.find(option => option.defaultOption) || 0;
                changePriceWithSelectedExtraOption(defaultOption.price);
            }
        }
    }, [newOrderDrinkName]);

    React.useEffect(() => {
        if (newOrderDrink) {
            handleSaveOrder();
        }
    }, [newOrderDrink]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleUpdateOrder = (order: IOrder) => {
        SaveOrder(order).then(() => {
            // Update the order in the state
            const orderIndex = orders.findIndex(o => o.id === order.id);
            orders[orderIndex] = order;
            setOrders([...orders]);

            setSelectedOption(0);
            setNewOrderDrink(null);
        });
    }

    const handleDeleteOrder = (order: IOrder) => {
        DeleteOrder(order.id).then(() => {
            deleteOrderFromCustomer(order);
        });
    }

    const handleDeleteCustomer = (id: number) => {
        DeleteCustomer(id).then(() => {
            // Remove the customer from the state
            const newCustomers = customers.filter(customer => customer.id !== id);
            setCustomers(newCustomers);
        });
    }

    const handleCloseAddCustomer = () => {
        setAddingNewCustomer(false);
    }

    const handleSaveCustomer = () => {
        let id = 0;
        SaveCustomer({
            name: newCustomerName,
            orders: []
        }).then(customer => {
            setCustomers([...customers, customer]);
            setSelectedCustomer(customer);
            id = customer.id;
        }).finally((customer) => {
            setNewCustomerName('');
            setAddingNewCustomer(false);
            setAddingOrder(true);
            setExpandedCustomer(id);
            if (bottomRef.current) {
                bottomRef.current.scrollIntoView({behavior: 'smooth'});
            }
        })
    }

    const getDrinkFromName = (name: string) => {
        return drinks.find(drink => drink.name === name);
    }

    const handleSaveOrder = () => {
        // Get the drink by name
        let sum: number = 0;
        let drinkOptionId: number = 0;
        let orderPrice: number = 0; // New variable to store the price of the order

        if (newOrderDrink && newOrderDrink.drinkExtraOptions) {
            // Set orderPrice to the original price of the drink
            orderPrice = drinks.find(drink => drink.id === newOrderDrink.id)?.price || 0;

            // Check for default option and add the price to the order price
            // If the extra option is not the default option, add the price to the order price
            for (let i = 0; i < newOrderDrink.drinkExtraOptions.length; i++) {
                if (newOrderDrink.drinkExtraOptions[i].defaultOption) {
                    drinkOptionId = newOrderDrink.drinkExtraOptions[i].id;
                    orderPrice += newOrderDrink.drinkExtraOptions[i].price;
                    break;
                }
            }
            sum = orderPrice * newOrderQuantity;
        } else {
            sum = newOrderSum;
        }

        const order: IOrder = {
            customer: {id: customers.find(customer => customer.id === selectedCustomer?.id)?.id},
            status: newOrderPaid,
            sum: sum,
            drink: {id: newOrderDrink.id} as IDrink,
            dateTime: new Date().toISOString(),
            quantity: newOrderQuantity,
            drinkOptionId: drinkOptionId,
            user: {id: user.id} as IUser
        }
        SaveOrder(order).then((order) => {
            setOrders([...orders, order]);
        }).then(() => {
            GetAllOrders().then((orders) => {
                setOrders(orders);
            });
        })
        // Reset the form and close it
        setNewOrderDrinkName('');
        setNewOrderQuantity(1);
        setNewOrderPaid(false);
        setAddingOrder(false);
        setSelectedOption(0);
        setNewOrderDrink(null);
    }

    const isDrinkAlreadyOrderedToday = (drink: IDrink) => {
        // Check if the drink is in the orders of today for the selected customer
        const today = new Date().toISOString().split('T')[0];
        const selectedCustomerTodayOrders = currentDayOrders.filter((order) => order.customer.id === selectedCustomer?.id && order.dateTime.toString().split('T')[0] === today);

        return selectedCustomerTodayOrders.some(order => order.drink.id === drink.id);
    }

    const deleteOrderFromCustomer = (order: IOrder) => {
        const orderIndex = orders.findIndex(o => o.id === order.id);
        orders.splice(orderIndex, 1);
        setOrders([...orders]);
    }

    const calculateOrderSums = (customer: ICustomer) => {
        let paidSum = 0;
        let unpaidSum = 0;
        let tmpSum = 0;

        // Check if the drink is in drinks -> drinks.map((drink) => drink.name).includes(newValue)
        // If not, divide the sum by the quantity to get the price of one drink
        if (drinks.map((drink) => drink.name).includes(newOrderDrinkName)) {
            tmpSum = newOrderSum / newOrderQuantity;
        }

        // Filter the orders based on the selected day before calculating the sums
        orders.filter(order => selectedDay === 'Alle' || order.dateTime.split('T')[0] === selectedDay)
            .filter(order => order.customer.id === customer.id)
            .forEach(order => {
                if (order.status) {
                    paidSum += tmpSum === 0 ? order.sum : tmpSum;
                } else {
                    unpaidSum += tmpSum === 0 ? order.sum : tmpSum;
                }
            });

        return {paidSum, unpaidSum};
    }

    const changePriceWithSelectedExtraOption = (extraPrice: number) => {
        if (extraPrice === 0) {
            // Reset the price to the original price, original prices are in originalDrinks
            setNewOrderDrink({
                ...newOrderDrink,
                price: drinks.find(drink => drink.id === newOrderDrink.id)?.price
            });
        } else {
            setNewOrderDrink({
                ...newOrderDrink,
                price: newOrderDrink?.price + extraPrice
            });
        }
    }

    const allOrdersArePaid = (customer: ICustomer) => {
        if (selectedDay === 'Alle') {
            const customerOrders = orders.filter(order => order.customer.id === customer.id);
            if (customerOrders.length === 0) {
                return false;
            }
            return customerOrders.every(order => order.status);
        }

        const customerOrdersOnSelectedDay = orders.filter(order =>
            order.customer.id === customer.id &&
            order.dateTime.toString().split('T')[0] === selectedDay
        );

        if (customerOrdersOnSelectedDay.length === 0) {
            return false;
        }
        return customerOrdersOnSelectedDay.every(order => order.status);
    }

    const handleShowCustomersWithStatus = () => {
        // Filter orders based on the selected day
        const ordersOnSelectedDay = orders.filter(order => selectedDay === 'Alle' || order.dateTime.toString().split('T')[0] === selectedDay);

        // If showAllPaidCustomers is true, show all customers, including those who have paid all their orders on the selected day or have no orders on the selected day
        // If showAllPaidCustomers is false, show only customers who have not paid all their orders on the selected day or have not ordered anything yet
        const result = customers.filter(customer => {
            const customerOrdersOnSelectedDay = ordersOnSelectedDay.filter(order => order.customer.id === customer.id);
            const allOrdersPaid = customerOrdersOnSelectedDay.every(order => order.status);
            return showAllPaidCustomers ? allOrdersPaid : !allOrdersPaid || customerOrdersOnSelectedDay.length === 0;
        });
        setUnpaidCustomers(result);
    }

    const handleExtraOptionChange = (order: IOrder, newExtraOptionId: number) => {
        if (newExtraOptionId === 0) {
            // Create a new order object
            const newOrder = {
                ...order,
                drinkOptionId: 0,
                sum: order.drink.price * order.quantity
            };

            // Update the order in the backend
            UpdateOrder(newOrder).then(() => {
                // Update the order in the state
                const orderIndex = orders.findIndex(o => o.id === order.id);
                orders[orderIndex] = newOrder;
                setOrders([...orders]);
            });
            return;
        }
        // Find the new extra option in the drink's extra options
        const newExtraOption = order.drink.drinkExtraOptions.find(option => option.id === newExtraOptionId);

        if (newExtraOption) {
            // Create a new order object
            const newOrder = {
                ...order,
                drinkOptionId: newExtraOption.id,
            };

            // If the selected option is "Keine Option", set the sum to the original price of the drink
            // Else, add the price of the extra option to the original price of the drink
            newOrder.sum = (order.drink.price + newExtraOption.price) * order.quantity;

            // Update the order in the backend
            UpdateOrder(newOrder).then(() => {
                // Update the order in the state
                const orderIndex = orders.findIndex(o => o.id === order.id);
                orders[orderIndex] = newOrder;
                setOrders([...orders]);
            });
        }
    }

    return (
        <Box sx={{
            width: '100vw',
            height: '80vh',
            marginTop: {
                xs: '30%', // Für kleine Geräte
                sm: '25%', // Für mittlere Geräte
                md: '20%', // Für große Geräte
                lg: '10%', // Für sehr große Geräte
            }
        }}>
            {(user.role.includes('A') || user.role.includes('T')) &&
                <Fab aria-label="Neues Getränk hinzufügen"
                     onClick={() => {
                         setAddingNewCustomer(true);
                     }}
                     onMouseDown={() => setIsDragging(true)}
                     onMouseUp={() => setIsDragging(false)}
                     onMouseMove={(e) => {
                         if (isDragging) {
                             setFabPosition(prevPosition => ({
                                 x: prevPosition.x - e.movementX,
                                 y: prevPosition.y - e.movementY
                             }));
                         }
                     }}
                     onTouchStart={(e) => {
                         setIsDragging(true);
                         const touch = e.touches[0];
                         setPrevTouchPosition({x: touch.clientX, y: touch.clientY});
                     }}
                     onTouchEnd={() => setIsDragging(false)}
                     onTouchMove={(e) => {
                         e.stopPropagation();
                         e.preventDefault();
                         if (isDragging) {
                             const touch = e.touches[0];
                             setFabPosition(prevPosition => ({
                                 x: prevPosition.x - touch.clientX + prevTouchPosition.x,
                                 y: prevPosition.y - touch.clientY + prevTouchPosition.y
                             }));
                             setPrevTouchPosition({x: touch.clientX, y: touch.clientY});
                         }
                     }}
                     sx={{
                         backgroundColor: 'rgb(192,173,109)',
                         boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                         width: '100px',
                         height: '100px',
                         position: 'fixed',
                         bottom: `${fabPosition.y}px`,
                         right: `${fabPosition.x}px`,
                         zIndex: 99
                     }}
                >
                    <AddIcon/>
                    <FaceRetouchingNaturalIcon/>
                </Fab>
            }
            <TextField
                fullWidth
                variant="outlined"
                label="Suche Kunde..."
                value={searchCustomer}
                onChange={e => {
                    if (e.target.value) {
                        setSearchCustomer(e.target.value);
                    } else {
                        setSearchCustomer('');
                    }
                }}
                sx={{
                    marginTop: 1,
                    marginBottom: 2,
                    border: 1
                }}
            />
            <Select
                value={selectedDay}
                onChange={(event) => setSelectedDay(event.target.value)}
                fullWidth
            >
                <MenuItem value="Alle" disabled>Alle</MenuItem>
                <MenuItem value={new Date().toISOString().split('T')[0]}>Heute</MenuItem>
                {uniqueDays.map((day) => (
                    <MenuItem value={day}>{day}</MenuItem>
                ))}
            </Select>
            <Box sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAllPaidCustomers}
                            onChange={(event) => {
                                setShowAllPaidCustomers(event.target.checked);
                                // handleShowAllPaid();
                            }}
                        />
                    }
                    label={'Kunden anzeigen die alles bezahlt haben?'}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{
                                fontWeight: 'bold',
                            }}>Kunde</TableCell>
                            <TableCell align="right" sx={{
                                fontWeight: 'bold',
                            }}>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{
                height: '60%',
                maxHeight: '60%',
                width: '100vw',
                maxWidth: '100vw',
                overflowY: 'auto',
            }}>
                <Table>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : result
                        ).map((customer: ICustomer, index: number) => (
                            <TableRow key={customer.id} ref={bottomRef} sx={{
                                backgroundColor: allOrdersArePaid(customer) ? 'rgba(103,143,54,0.49)' : 'rgba(255,255,255,0)',
                            }}>
                                <TableCell component="th" scope="row" align="left">
                                    <Accordion expanded={expandedCustomer === customer.id}
                                               onChange={(event, isExpanded) => {
                                                   setExpandedCustomer(isExpanded ? customer.id : null);
                                                   if (!isExpanded) {
                                                       setAddingOrder(false);
                                                   }
                                                   if (customer.id !== selectedCustomer?.id) {
                                                       setAddingOrder(false);
                                                   }
                                               }}
                                               sx={{
                                                   boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.35)',
                                                   width: '100%'
                                               }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            onClick={() => setSelectedCustomer(customer)}
                                            sx={{
                                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.35)',
                                                backgroundColor: allOrdersArePaid(customer) ? 'rgba(103,143,54,0.63)' : 'rgba(255,255,255,0)'
                                            }}
                                        >
                                            <Typography>{customer.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails onClick={() => setSelectedCustomer(customer)} sx={{
                                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                                        }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Getränkename</TableCell>
                                                        <TableCell>Anzahl</TableCell>
                                                        <TableCell>Bezahlt</TableCell>
                                                        <TableCell>Summe</TableCell>
                                                        <TableCell>Uhrzeit</TableCell>
                                                        <TableCell align="right">Aktionen</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {orders.filter(order => selectedDay === 'Alle' ||
                                                        order.dateTime.toString().split('T')[0] === selectedDay)
                                                        .filter(order => order.customer.id === customer.id)
                                                        .map((order) => (
                                                            <TableRow key={order.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {order.drink.name}
                                                                    {order.drink && order.drink.drinkExtraOptions ? (
                                                                        <Select
                                                                            value={order.drinkOptionId}
                                                                            onChange={(event) => handleExtraOptionChange(order, event.target.value)}
                                                                        >
                                                                            <MenuItem key={0} value={0}>
                                                                                Keine Option
                                                                            </MenuItem>
                                                                            {order.drink.drinkExtraOptions.map(option => (
                                                                                <MenuItem key={option.id}
                                                                                          value={option.id}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    ) : null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton onClick={() => {
                                                                        if (order.quantity > 0) {
                                                                            order.quantity -= 1;
                                                                            // Update the sum of the order
                                                                            const extraOptionPrice = order.drinkOptionId !== 0 ? order.drink.drinkExtraOptions.find(option => option.id === order.drinkOptionId)?.price || 0 : 0;
                                                                            order.sum = parseFloat(((order.drink.price + extraOptionPrice) * order.quantity).toFixed(2));
                                                                            handleUpdateOrder(order);
                                                                        }
                                                                    }}>
                                                                        <RemoveIcon/>
                                                                    </IconButton>
                                                                    {order.quantity}
                                                                    <IconButton onClick={() => {
                                                                        order.quantity += 1;
                                                                        // Update the sum of the order
                                                                        const extraOptionPrice = order.drinkOptionId !== 0 ? order.drink.drinkExtraOptions.find(option => option.id === order.drinkOptionId)?.price || 0 : 0;
                                                                        order.sum = parseFloat(((order.drink.price + extraOptionPrice) * order.quantity).toFixed(2));
                                                                        handleUpdateOrder(order);
                                                                    }}>
                                                                        <AddIcon/>
                                                                    </IconButton>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={order.status}
                                                                                onChange={(event) => {
                                                                                    order.status = event.target.checked;
                                                                                    handleUpdateOrder(order);
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={order.status ? 'Ja' : 'Nein'}
                                                                    />
                                                                </TableCell>
                                                                <TableCell sx={{
                                                                    color: order.status ? 'green' : 'red'
                                                                }}>
                                                                    {order.sum.toFixed(2)}€
                                                                </TableCell>
                                                                <TableCell>
                                                                    {order.dateTime.toString().split('T')[1].split('.')[0]}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Button variant="contained"
                                                                            onClick={() => handleDeleteOrder(order)}
                                                                            sx={{
                                                                                backgroundColor: '#8d1313'
                                                                            }}>
                                                                        <DeleteIcon/>
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                            {addingOrder ? (
                                                <>
                                                    <Divider/>
                                                    <FormGroup
                                                        sx={{
                                                            marginTop: 2,
                                                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                                                            padding: 2,
                                                        }}
                                                    >
                                                        <Autocomplete
                                                            options={drinks.map(drink => drink.name)}
                                                            value={newOrderDrinkName}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            onInputChange={(event, newValue) => {
                                                                setNewOrderDrinkName(newValue);
                                                                const isDrink: boolean = drinks.map((drink) => drink.name).includes(newValue)
                                                                setIsSumEnabled(!isDrink);
                                                                if (isDrink) {
                                                                    const drink = getDrinkFromName(newValue);
                                                                    setNewOrderDrink(drink);
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params}
                                                                                                label="Getränkename"/>}
                                                        />
                                                        <Divider/>
                                                        <Button variant="contained"
                                                                onClick={() => setAddingOrder(false)}
                                                                sx={{
                                                                    backgroundColor: '#6c2929'
                                                                }}
                                                        >
                                                            Abbrechen
                                                        </Button>
                                                    </FormGroup>
                                                </>
                                            ) : (
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <Button variant="contained" color="primary"
                                                            onClick={() => {
                                                                setAddingOrder(true);
                                                            }}
                                                            fullWidth
                                                    >
                                                        Bestellung hinzufügen
                                                    </Button>
                                                    <Box sx={{
                                                        marginTop: 2,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        width: '100%'
                                                    }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold',
                                                            fontSize: 16,
                                                        }}>
                                                            Bezahlt:
                                                            <Typography sx={{
                                                                color: 'green',
                                                                marginLeft: 1
                                                            }}>
                                                                {calculateOrderSums(customer).paidSum.toFixed(2)}€
                                                            </Typography>
                                                        </Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={allOrdersArePaid(customer)}
                                                                    onChange={(event) => {
                                                                        orders.filter(order => order.customer.id === customer.id).forEach(order => {
                                                                            order.status = true;
                                                                            handleUpdateOrder(order);
                                                                        });
                                                                    }}
                                                                    disabled={allOrdersArePaid(customer)}
                                                                />
                                                            }
                                                            label="Alles Bezahlt?"/>
                                                        <Typography sx={{
                                                            fontWeight: 'bold',
                                                            fontSize: 16,
                                                        }}>
                                                            Nicht Bezahlt:
                                                            <Typography sx={{
                                                                color: 'red',
                                                                marginLeft: 3
                                                            }}>
                                                                {calculateOrderSums(customer).unpaidSum.toFixed(2)}€
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </TableCell>
                                <TableCell component="th" scope="row" align="left">
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around'
                                    }}>
                                        <Button variant="contained"
                                                sx={{
                                                    backgroundColor: '#678f36',
                                                    width: '50%'
                                                }}
                                        >
                                            <EditNoteIcon/>
                                        </Button>
                                        <Button variant="contained"
                                                onClick={() => {
                                                    handleDeleteCustomer(customer.id);
                                                }}
                                                sx={{
                                                    backgroundColor: '#8d1313',
                                                    width: '50%'
                                                }}
                                        >
                                            <DeleteIcon/>
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button fullWidth sx={{
                backgroundColor: localStorage.getItem('theme') === 'dark' ? '#2d2d2d' : '#ffffff',
                color: localStorage.getItem('theme') === 'dark' ? '#ffffff' : '#000000',
                marginTop: 2,
                marginBottom: 2,
                border: 1
            }} onClick={() => {
                bottomRef.current.scrollIntoView({behavior: 'smooth'});
            }}>
                Scroll down
                <KeyboardDoubleArrowDownIcon/>
            </Button>
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={customers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}
            />
            <Dialog open={addingNewCustomer} onClose={handleCloseAddCustomer}>
                <DialogTitle>Neuen Kunden hinzufügen</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <TextField
                            value={newCustomerName}
                            onChange={(event) => {
                                setNewCustomerName(event.target.value);
                                if (customers.filter(customer => customer.name.toLowerCase() === event.target.value.toLowerCase()).length > 0) {
                                    setNewCustomerNameWarning(true);
                                } else {
                                    setNewCustomerNameWarning(false);
                                }
                            }}
                            label="Kundenname"
                            autoFocus
                            fullWidth
                            sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                alignText: 'center'
                            }}
                        />
                        {newCustomerNameWarning &&
                            <Box>
                                <Divider/>
                                <Typography sx={{
                                    marginTop: 2,
                                    marginBottom: 2,
                                    color: '#ffca48'
                                }}>
                                    Warnung: Der Name existiert bereits! Falls der selbe Kunde gemeint ist,
                                    der bereits einmal verwendet wurde, verwende bitte den bereits
                                    existierenden Kunden (Suche Kunde...).
                                </Typography>
                                <Divider/>
                            </Box>
                        }
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleSaveCustomer}
                            sx={{
                                backgroundColor: '#678f36'
                            }}
                            disabled={newCustomerName.trim() === ''}
                        >
                            Kunde hinzufügen
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default Customers;